import * as React from "react";
import compose from "utils/compose";
import withMygroupsContext from "HOC/withMygroupsContext";
import withSessionContext from "HOC/withSessionContext";
import { Persist } from "react-persist";
import { urls } from "HOC/withApiService";
import { routePaths } from "../containers/Routes/Routes";
import { saveAs } from "file-saver";
const qs = require("query-string");
const MygroupsContext = React.createContext();
export const MygroupsContextConsumer = MygroupsContext.Consumer;

let initialState = {
  page: "Mygroups",
  quizQnAnsData: [],
  mygroupsData: {},
  mygroupsCreateData: {},
  itemType: "quiz",
  individualQuizResponse: {},
  lessonAssignedGroupList: [],
  TXNames: {
    level1: "Did Not Meet Grade Level",
    level2: "Approaches Grade Level",
    level3: "Meets Grade Level",
    level4: "Masters Grade Level",
  },
  FLNames: {
    level1: "Approaching Level",
    level2: "Developing Level",
    level3: "Satisfactory Level",
    level4: "Proficient Level",
  },
  rangeVal: { Approaching: 50, Developing: 70, Satisfactory: 85 },
};

class MygroupsContextProvider extends React.Component {
  state = {
    ...initialState,

    _setState: (next, cb) => {
      //Call this method from any child component to update the Mygroups state
      if (typeof next === "function") {
        this.setState(
          (state) => next(state),
          () => {
            cb && typeof cb === "function" && cb(this.state);
            console.log("-------new Mygroups context:", this.state);
          }
        );
      } else {
        this.setState({ ...next }, () => {
          cb && typeof cb === "function" && cb(this.state);
          console.log("-------new Mygroups context:", this.state);
        });
      }
    },

    resetState: () => {
      this.setState(
        (state) => ({
          ...initialState,
          MygroupsType: state.MygroupsType, //remember this so when Mygroups is complete, operator only has to choose traveler type
        }),
        () => {
          console.log("--------Mygroups context reset:", this.state);
        }
      );
    },

    getStudentAssignmentDetails: async (student) => {
      const { post } = this.props.sessionContext;

      let payload = {
        user_puid: student.user_puid,
      };

      let res = await post({
        url: `${urls.reports.getAssignmentsByUser}?${qs.stringify(payload)}`,
        method: "GET",
      });

      if (res) {
        // res = JSON.parse(res);
        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            individualStudentAssignments: res.data,
          }));
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "reports error";
      }
    },
    getAssignmentsByLesson: async (payload) => {
      const { post } = this.props.sessionContext;

      let res = await post({
        url: `${urls.reports.getAssignmentsByLesson}?${qs.stringify(payload)}`,
        method: "GET",
      });

      if (res) {
        // res = JSON.parse(res);
        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            studentProgressData: res.data,
          }));
          return res.data;
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "reports error";
      }
    },
    getAssignmentProgress: async (payload) => {
      const { post } = this.props.sessionContext;
      this.state._setState((state) => ({
        ...state,
        assignmentProgress: [],
      }));
      let res = await post({
        url: `${urls.reports.getAssignmentProgress}?${qs.stringify(payload)}`,
        method: "GET",
      });

      if (res) {
        // res = JSON.parse(res);
        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            assignmentProgress: res.data,
          }));
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "reports error";
      }
    },
    getMygroupsData: async (userState) => {
      const { post, userData } = this.props.sessionContext;
      let queryString;

      if (!userState) {
        queryString = `&group_puid=''`;
      } else {
        queryString = `&group_puid=${userState.group_puid}`;
      }

      let res = await post({
        // url: `${urls.mygroups.mygroupsData}?user_id=4AED5926-115A-424D-B19D-BAE63F850EC3${queryString}`
        // url: `${urls.mygroups.mygroupsData}?user_puid=${userInfo.unique_name}${queryString}`,
        url: `${urls.mygroups.mygroupsData}?user_puid=${userData.userPuid}${queryString}`,
        method: "GET",
      });

      if (res) {
        // res = JSON.parse(res);
        if (res.status === "success") {
          if (!userState) {
            this.state._setState((state) => ({
              ...state,
              mygroupsData: res.data,
            }));
          } else {
            this.state._setState((state) => ({
              ...state,
              mygroupsData: {
                ...state.mygroupsData,
                data: res.data ? res.data.data : {},
              },
            }));
          }
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
        return res;
      } else {
        throw "class player track error";
      }
    },

    getMygroupsCreateData: async (userState) => {
      const { post, userData } = this.props.sessionContext;
      let queryString;
      if (!userState.group_puid) {
        // queryString = `&group_puid=`;
        queryString = ``;
      } else {
        queryString =
          userState.group_type === "group"
            ? `&group_puid=${userState.group_puid}`
            : `&parent_puid=${userState.group_puid}&subgroup_puid=${
                userState.subgroup_puid ? userState.subgroup_puid : ""
              }`;
      }
      this.state._setState((state) => ({
        ...state,
        mygroupsCreateData: {},
      }));
      let res = await post({
        // url: `${urls.mygroups.mygroupsData}?user_id=4AED5926-115A-424D-B19D-BAE63F850EC3${queryString}`
        url: `${
          userState.group_type === "group"
            ? urls.mygroups.mygroupsCreateData
            : urls.mygroups.mygroupsSubgroup
        }?user_puid=${userData.userPuid}${queryString}`,
        method: "GET",
      });

      if (res) {
        // res = JSON.parse(res);
        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            mygroupsCreateData: res.data,
          }));
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "class create track error";
      }
    },

    deleteMygroup: async (group_id) => {
      const { post, userData } = this.props.sessionContext;
      // let group_id = "";
      // let isAssignment = data.is_assignment;
      let res = await post({
        url: `${urls.mygroups.mygroupsDelete}?user_puid=${userData.userPuid}&group_puid=${group_id}`,
        method: "DELETE",
      });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            mygroupDeleteData: res.data,
          }));
        } else {
          // this.props.sessionContext.showNotification({
          //   message: "Cannot Delete this group as it has subgroups",
          //   type: "error",
          // });
          throw res.message;
        }
      } else {
        throw "Delete Mygroup Error";
      }
    },
    updateGroupName: async (data) => {
      const { post, showNotification } = this.props.sessionContext;

      const res = await post({
        contentType: "application/json",
        isUrlEncoded: true,
        url: urls.mygroups.updateGroupName,
        data,
      });
      if (res) {
        if (res.status === "success") {
          showNotification({
            message: "Success",
            type: "success",
          });
          return res.status;
        } else if (res.status === "error") {
          showNotification({
            message: res.message,
            type: "error",
          });
        } else {
          throw "error";
        }
      }
    },
    removeUsersFromGroup: async (data) => {
      const { post, showNotification } = this.props.sessionContext;

      const res = await post({
        contentType: "application/json",
        isUrlEncoded: true,
        url: urls.mygroups.removeUsersFromGroup,
        data,
      });
      if (res) {
        if (res.status === "success") {
          showNotification({
            message: "Success",
            type: "success",
          });
          return res.status;
        } else if (res.status === "error") {
          showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        } else {
          throw "error";
        }
      }
    },
    getStudentViewSummary: async (group_id) => {
      const { post, userData } = this.props.sessionContext;

      let res = await post({
        url: `${urls.reports.getStudentViewSummary}?user_puid=${userData.userPuid}&group_puid=${group_id}`,
        method: "GET",
        data: undefined,
      });

      if (res) {
        // res = JSON.parse(res);
        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            studentSummary: res.data,
          }));
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "Reports Error";
      }
    },
    exportCsv: async (data) => {
      const { post, userData } = this.props.sessionContext;
      const token =
        this.props.sessionContext.sessionStorageGet("token") ||
        this.props.sessionContext.token ||
        "";
      console.log(token);
      let res = fetch(
        `${this.props.sessionContext.hostURL}${urls.reports.exportCsv}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          responseType: "blob",
          body: JSON.stringify(data),
          referrer: "no-referrer",
        }
      ).then(async (response) => {
        const filename = `${data.title} - Report.xlsx`;
        const blob = await response.blob();

        // Download the file
        saveAs(blob, filename);
      });

      // let res = await post({
      //   url: `${urls.reports.exportCsv}`,
      //   contentType: "application/json",
      //   isUrlEncoded: true,
      //   data: data,
      // });

      // console.log("mmmmmmmmmmmmmmmm", res);
      // if (res) {
      //   // res = JSON.parse(res);
      // } else {
      //   throw "Reports Error";
      // }
    },

    getStudentProgress: async (id) => {
      const { post, userData, startSpinner, stopSpinner } =
        this.props.sessionContext;

      try {
        startSpinner();
        let res = await post({
          method: "GET",
          url: `${urls.dashboard.studentDashboard}?user_puid=${id}`,
          data: undefined,
        });

        if (res) {
          if (res.status === "success") {
            stopSpinner();
            this.state._setState((state) => ({
              ...state,
              studentProgressData: res.data,
            }));
          } else {
            this.props.sessionContext.showNotification({
              message: res.message,
              type: "error",
            });
            throw res.message;
          }
        } else {
          stopSpinner();
          throw "Dashboard error";
        }
      } catch (e) {
        stopSpinner();
        console.log("MY-GROUPS ERROR: ", e);
      } finally {
        stopSpinner();
      }
    },
    getStudentQuizResponse: async (data) => {
      const { post } = this.props.sessionContext;

      try {
        let res = await post({
          method: "GET",
          url: `${urls.reports.getStudentQuizResponse}?${qs.stringify(data)}`,
          data: undefined,
        });

        if (res) {
          if (res.status === "success") {
            this.state._setState((state) => ({
              ...state,
              quizReport: res.data,
            }));
          } else {
            this.props.sessionContext.showNotification({
              message: res.message,
              type: "error",
            });
            throw res.message;
          }
        } else {
          throw "Dashboard error";
        }
      } catch (e) {
        console.log("MY-GROUPS ERROR: ", e);
      }
    },
    getItemwisewQuiz: async (payload) => {
      const { post } = this.props.sessionContext;

      try {
        let res = await post({
          method: "GET",
          url: `${urls.reports.getItemwisewQuiz}?${qs.stringify(payload)}`,
          data: undefined,
        });

        if (res) {
          if (res.status === "success") {
            this.state._setState((state) => ({
              ...state,
              itemWiseReport: res.data,
            }));
          } else {
            this.state._setState((state) => ({
              ...state,
              itemWiseReport: [],
            }));
            throw res.message;
          }
        } else {
          throw "Dashboard error";
        }
      } catch (e) {
        console.log("MY-GROUPS ERROR: ", e);
      }
    },
    getStudentAssignments: async (group_id) => {
      const { post, userData } = this.props.sessionContext;

      let res = await post({
        url: `${urls.reports.getStudentAssignments}?user_puid=${userData.userPuid}&group_puid=${group_id}`,
        method: "GET",
        data: undefined,
      });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            studentAssignments: res.data,
          }));
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "Reports Error";
      }
    },
    getAssignmentViewSummary: async (group_id) => {
      const { post, userData } = this.props.sessionContext;

      let res = await post({
        url: `${urls.reports.getAssignmentViewSummary}?user_puid=${userData.userPuid}&group_puid=${group_id}`,
        method: "GET",
        data: undefined,
      });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            assignmentSummary: res.data,
          }));
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "Reports Error";
      }
    },
    getAssignmentResourceDetails: async (lesson_id) => {
      const { post } = this.props.sessionContext;
      this.state._setState((state) => ({
        ...state,
        assignmentResourceDetails: [],
      }));
      let res = await post({
        url: `${
          urls.reports.getAssignmentResourceDetails
        }?lesson_id=${lesson_id}&group_puid=${
          this.state.selectedGroup.group_puid ||
          this.state.selectedGroup.subgroup_puid
        }`,
        method: "GET",
        data: undefined,
      });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            assignmentResourceDetails: res.data,
            assignmentStatus: res.assignment_status,
            groupAssignSignature: res.learnosity_report_signature,
          }));
          return res;
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "Reports Error";
      }
    },

    addNewClass: async (data) => {
      const { post, userData } = this.props.sessionContext;
      const { mygroupData } = this.state;

      let dataObjectified = {
        user_puid: userData.userPuid,
        group_name: data.class_name,
        icon: data.group_icon,
        color: data.color_name,
        group_type: data.group_type,
        parent_group_puid: data.parent_gid,
        // group_users_list: data.student_list.toString(),
        group_users_list: JSON.stringify(data.student_list),
      };
      const queryParamas =
        "?user_puid=" +
        dataObjectified.user_puid +
        "&group_name=" +
        dataObjectified.group_name +
        "&icon=" +
        dataObjectified.icon +
        "&color=" +
        dataObjectified.color +
        "&group_type=" +
        dataObjectified.group_type +
        "&parent_group_puid=" +
        dataObjectified.parent_group_puid +
        "&group_users_list=" +
        dataObjectified.group_users_list;
      //
      let res = await post({
        url: `${urls.mygroups.mygroupsAdd}`,
        contentType: "application/json",
        isUrlEncoded: true,
        data: dataObjectified,
      });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "success",
          });
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "mygroups track error";
      }
    },

    updateClass: async (data) => {
      const { post, userData } = this.props.sessionContext;
      const { mygroupData } = this.state;
      console.log(data);

      let dataObjectified = {
        user_puid: userData.userPuid,
        group_name: data.class_name,
        icon: data.group_icon,
        color: data.color_name,
        group_type: data.group_type,
        parent_group_puid: data.parent_gid,
        // group_users_list: data.student_list.toString(),
        group_users_list: JSON.stringify(data.student_list),
        group_puid: data.mygroup_puid,
      };

      let res = await post({
        url: `${urls.mygroups.mygroupsUpdate}`,
        contentType: "application/json",
        isUrlEncoded: true,
        data: dataObjectified,
      });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "success",
          });
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "mygroups track error";
      }
    },
    getGroupUsersLabel: async (group_id) => {
      const { post, userData } = this.props.sessionContext;

      let res = await post({
        url: `${urls.mygroups.getGroupUsersLabel}?user_puid=${userData.userPuid}&group_puid=${group_id}`,
        method: "GET",
        data: undefined,
      });

      if (res) {
        // res = JSON.parse(res);
        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            printLabelData: res.data,
          }));
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "Labels Data Load Error";
      }
    },

    getGroupAssessmentReport: async (groupId, mode) => {
      const token =
        this.props.sessionContext.sessionStorageGet("token") ||
        this.props.sessionContext.token ||
        "";
      const { post, userData } = this.props.sessionContext;
      let res = await post({
        url: `${urls.reports.getGroupAssessmentReport}?user_puid=${userData.userPuid}&group_puid=${groupId}&mode=${mode}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: undefined,
      });

      if (res) {
        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            groupAssessmentReport: res.data,
          }));
        } else {
          this.state._setState((state) => ({
            ...state,
            groupAssessmentReport: [],
          }));
          throw res.message;
        }
      } else {
        throw "Data Load Error";
      }
    },

    getStudentAssessmentReport: async (groupId, mode) => {
      const token =
        this.props.sessionContext.sessionStorageGet("token") ||
        this.props.sessionContext.token ||
        "";
      const { post, userData } = this.props.sessionContext;
      let res = await post({
        url: `${urls.reports.getStudentAssessmentReport}?user_puid=${userData.userPuid}&group_puid=${groupId}&mode=${mode}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: undefined,
      });

      if (res) {
        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            studentAssessmentReport: res.data,
          }));
        } else {
          this.state._setState((state) => ({
            ...state,
            studentAssessmentReport: [],
          }));
          throw res.message;
        }
      } else {
        throw "Data Load Error";
      }
    },

    exportGroupReportCsv: async (data, mode) => {
      const token =
        this.props.sessionContext.sessionStorageGet("token") ||
        this.props.sessionContext.token ||
        "";
      let res = fetch(
        `${this.props.sessionContext.hostURL}${urls.reports.exportGroupReportCsv}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          responseType: "blob",
          body: JSON.stringify(data.report_data),
          referrer: "no-referrer",
        }
      ).then(async (response) => {
        console.log(response.headers.get("content-length"));
        const filename = `${data.report_data.title}_${mode}_Report(Group).xlsx`;
        const blob = await response.blob();
        saveAs(blob, filename);
      });
    },

    exportStudentReportCsv: async (data, mode) => {
      const token =
        this.props.sessionContext.sessionStorageGet("token") ||
        this.props.sessionContext.token ||
        "";

      let res = fetch(
        `${this.props.sessionContext.hostURL}${urls.reports.exportStudentReportCsv}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          responseType: "blob",
          body: JSON.stringify(data.report_data),
          referrer: "no-referrer",
        }
      ).then(async (response) => {
        console.log(response.headers.get("content-length"));
        const filename = `${data.report_data.title}_${mode}_Report(Student).xlsx`;
        const blob = await response.blob();
        saveAs(blob, filename);
      });
    },

    getIndividualAssessmentReport: async (
      groupId,
      mode,
      quiz,
      type,
      item = null
    ) => {
      const token =
        this.props.sessionContext.sessionStorageGet("token") ||
        this.props.sessionContext.token ||
        "";
      const { post, userData, active } = this.props.sessionContext;
      const Id = type == "group" ? quiz.Id : quiz.StudentPuid;
      let res = await post({
        url: `${urls.reports.getIndividualAssessmentReport}?group_puid=${groupId}&user_puid=${userData.userPuid}&id=${Id}&item_type=${mode}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: undefined,
      });

      if (res) {
        if (res.status === "success") {
          if (active === "staarReady") {
            this.state._setState((state) => ({
              ...state,
              selectedGroup: item,
              selectedLearnoItem: item,
            }));
          }
          this.state._setState((state) => ({
            ...state,
            individualAssessmentReport: res.data,
            assessmentId: Id,
            itemType: mode,
            selectedAssessment: item ?? quiz,
          }));
        } else {
          this.state._setState((state) => ({
            ...state,
            itemType: mode,
            individualAssessmentReport: [],
            selectedAssessment: item ?? quiz,
          }));
          if (active === "staarReady") {
            this.state._setState((state) => ({
              ...state,
              selectedGroup: item,
              selectedLearnoItem: item,
            }));
          }
          throw res.message;
        }
      } else {
        throw "Data Load Error";
      }
    },

    getIndividualStudentAssessmentReport: async (groupId, mode, quiz, type) => {
      const token =
        this.props.sessionContext.sessionStorageGet("token") ||
        this.props.sessionContext.token ||
        "";
      let quizType = mode;
      const reportKey =
        mode == "quiz"
          ? "individual_student_quiz_report"
          : "individual_student_assessment_report";
      const { post, userData } = this.props.sessionContext;
      const student_puid = type == "group" ? quiz.Id : quiz.StudentPuid;
      // quizType = quizType.at(0) == "l" ? quizType.slice(1) : quizType;
      let res = await post({
        url: `${urls.reports.getIndividualStudentAssessmentReport}?group_puid=${groupId}&user_puid=${userData.userPuid}&student_puid=${student_puid}&item_type=${quizType}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: undefined,
      });

      if (res) {
        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            individualStudentAssessmentReport: res.data,
            itemType: mode,
            reportKey: reportKey,
            selectedStudent_puid: student_puid,
            selectedAssessment: quiz,
          }));
        } else {
          this.state._setState((state) => ({
            ...state,
            itemType: mode,
            individualStudentAssessmentReport: [],
            selectedAssessment: quiz,
            selectedStudent_puid: student_puid,
          }));
          throw res.message;
        }
      } else {
        throw "Data Load Error";
      }
    },
    getStudentReleasedReports: async () => {
      const token =
        this.props.sessionContext.sessionStorageGet("token") ||
        this.props.sessionContext.token ||
        "";
      const { post, userData } = this.props.sessionContext;
      let res = await post({
        url: `${urls.reports.getStudentReleasedReports}?user_puid=${userData?.userPuid}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: undefined,
      });

      if (res) {
        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            studentReleasedReportsData: res.data,
            // reportKey: reportKey,
            // selectedAssessment: quiz,
          }));
          return res.data;
        } else {
          this.state._setState((state) => ({
            ...state,
            studentReleasedReportsData: [],
            // selectedAssessment: quiz,
            // selectedStudent_puid: student_puid,
          }));
          throw res.message;
        }
      } else {
        throw "Data Load Error";
      }
    },

    exportIndividualAssesmentReportCsv: async (data) => {
      const token =
        this.props.sessionContext.sessionStorageGet("token") ||
        this.props.sessionContext.token ||
        "";

      let res = fetch(
        `${this.props.sessionContext.hostURL}${urls.reports.exportIndividualAssesmentReportCsv}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          responseType: "blob",
          body: JSON.stringify(data.report_data),
          referrer: "no-referrer",
        }
      ).then(async (response) => {
        console.log(response.headers.get("content-length"));
        const filename = "IndividualAssesmentReport.xlsx";
        const blob = await response.blob();
        saveAs(blob, filename);
      });
    },

    exportIndividualStudentQuizReportCsv: async (data) => {
      const token =
        this.props.sessionContext.sessionStorageGet("token") ||
        this.props.sessionContext.token ||
        "";

      let res = fetch(
        `${this.props.sessionContext.hostURL}${urls.reports.exportIndividualStudentQuizReportCsv}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          responseType: "blob",
          body: JSON.stringify(data.report_data),
          referrer: "no-referrer",
        }
      ).then(async (response) => {
        console.log(response.headers.get("content-length"));
        const filename = "IndividualStudentQuizReport.xlsx";
        const blob = await response.blob();
        saveAs(blob, filename);
      });
    },

    exportIndividualStudentAssessmentReportCsv: async (data) => {
      const token =
        this.props.sessionContext.sessionStorageGet("token") ||
        this.props.sessionContext.token ||
        "";

      let res = fetch(
        `${this.props.sessionContext.hostURL}${urls.reports.exportIndividualStudentAssessmentReportCsv}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          responseType: "blob",
          body: JSON.stringify(data.report_data),
          referrer: "no-referrer",
        }
      ).then(async (response) => {
        console.log(response.headers.get("content-length"));
        const filename = "IndividualStudentAssessmentReport.xlsx";
        const blob = await response.blob();
        saveAs(blob, filename);
      });
    },

    getIndividualQuizAttempts: async (
      groupId,
      student_puid,
      quiz_id,
      result_id
    ) => {
      const token =
        this.props.sessionContext.sessionStorageGet("token") ||
        this.props.sessionContext.token ||
        "";
      const { post, userData } = this.props.sessionContext;
      let res = await post({
        url: `${urls.reports.getIndividualQuizAttempts}?user_puid=${userData.userPuid}&group_puid=${groupId}&student_puid=${student_puid}&quiz_id=${quiz_id}&result_id=${result_id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: undefined,
      });

      if (res) {
        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            quizAttemptDetails: res.data,
          }));
          console.log("getIndividualQuizAttempts", res.data);
          return res.data;
        } else {
          // this.props.sessionContext.showNotification({
          //   message: res.message,
          //   type: "error",
          // });
          throw res.message;
        }
      } else {
        throw "Data Load Error";
      }
    },
    getIndividualQuizResponse: async (
      groupId,
      student_puid,
      quiz_id,
      result_id,
      assessmentType,
      isFromMyGroup
    ) => {
      const token =
        this.props.sessionContext.sessionStorageGet("token") ||
        this.props.sessionContext.token ||
        "";
      const { post, userData } = this.props.sessionContext;

      let res = await post({
        url: `${urls.reports.getIndividualQuizResponse}?user_puid=${userData.userPuid}&group_puid=${groupId}&student_puid=${student_puid}&quiz_id=${quiz_id}&result_id=${result_id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: undefined,
      });

      if (res) {
        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            individualQuizResponse: res.data,
            assessmentType: assessmentType,
            assessmentId: quiz_id,
            selectedStudent_puid: student_puid,
            itemType: "quiz",
          }));
          localStorage.setItem("mygroups-state", JSON.stringify(this.state));
          return res.data;
        } else {
          this.state._setState((state) => ({
            ...state,
            individualQuizResponse: state.individualQuizResponse || {},
            assessmentType: assessmentType,
            assessmentId: quiz_id,
            selectedStudent_puid: student_puid,
            itemType: "quiz",
          }));
          throw res.message;
        }
      } else {
        throw "Data Load Error";
      }
    },

    getIndividualAssessmentResponse: async (
      groupId,
      student_puid,
      quiz_id,
      result_id,
      assessmentType,
      isFromMyGroup
    ) => {
      const token =
        this.props.sessionContext.sessionStorageGet("token") ||
        this.props.sessionContext.token ||
        "";
      const { post, userData } = this.props.sessionContext;
      let res = await post({
        url: `${urls.reports.getIndividualAssessmentResponse}?user_puid=${userData.userPuid}&group_puid=${groupId}&student_puid=${student_puid}&assessment_id=${quiz_id}&result_id=${result_id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: undefined,
      });

      if (res) {
        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            individualQuizResponse: res.data,
            assessmentType: assessmentType,
            assessmentId: quiz_id,
            selectedStudent_puid: student_puid,
            itemType: state.itemType === "practice" ? "practice" : "assessment",
          }));
          localStorage.setItem("mygroups-state", JSON.stringify(this.state));
          return res.data;
        } else {
          this.state._setState((state) => ({
            ...state,
            individualQuizResponse: state.individualAssessmentReport || {},
            assessmentType: assessmentType,
            assessmentId: quiz_id,
            selectedStudent_puid: student_puid,
            itemType: state.itemType === "practice" ? "practice" : "assessment",
          }));
          throw res.message;
        }
      } else {
        throw "Data Load Error";
      }
    },
    getIndividualAssessmentAttempts: async (
      groupId,
      student_puid,
      quiz_id,
      result_id
    ) => {
      const token =
        this.props.sessionContext.sessionStorageGet("token") ||
        this.props.sessionContext.token ||
        "";
      const { post, userData } = this.props.sessionContext;
      let res = await post({
        url: `${urls.reports.getIndividualAssessmentAttempts}?user_puid=${userData.userPuid}&group_puid=${groupId}&student_puid=${student_puid}&assessment_id=${quiz_id}&result_id=${result_id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: undefined,
      });

      if (res) {
        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            quizAttemptDetails: res.data,
          }));
          return res.data;
        } else {
          throw res.message;
        }
      } else {
        throw "Data Load Error";
      }
    },

    exportItemAnalysisReport: async (data, mode) => {
      const token =
        this.props.sessionContext.sessionStorageGet("token") ||
        this.props.sessionContext.token ||
        "";

      let res = fetch(
        `${this.props.sessionContext.hostURL}${urls.reports.exportItemAnalysisReport}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          responseType: "blob",
          body: JSON.stringify(data),
          referrer: "no-referrer",
        }
      ).then(async (response) => {
        console.log(response.headers.get("content-length"));
        const filename = `${data.rc_header}_Item_Analysis_Report(Student).xlsx`;
        const blob = await response.blob();
        saveAs(blob, filename);
      });
    },
    getItemAnalysisGroup: async ({
      selectedGrade,
      selectedQuiz,
      quizAssess,
      selectedDistrict,
      selectedState,
      selectedSchool,
      startDate,
      endDate,
      spanish_id,
      groupId,
    }) => {
      const token =
        this.props.sessionContext.sessionStorageGet("token") ||
        this.props.sessionContext.token ||
        "";

      fetch(
        `${this.props.sessionContext.hostURL}/ETLReport/get_itemanalysis_group?grade_id=${selectedGrade}&quiz_id=${selectedQuiz}&spanish_id=${spanish_id}&assessment_type_id=${quizAssess}&district_id=${selectedDistrict}&state_id=${selectedState}&year_start_date=${startDate}&year_end_date=${endDate}&school_id=${selectedSchool}&request_type=G&group_puid=${groupId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          responseType: "blob",
          referrer: "no-referrer",
        }
      ).then(async (response) => {
        const filename = `Group_Level_Performance_Report.xlsx`;
        const blob = await response.blob();

        // Download the file
        saveAs(blob, filename);
      });
    },

    getItemAnalysisStudent: async ({
      selectedGrade,
      selectedQuiz,
      quizAssess,
      selectedDistrict,
      selectedState,
      selectedSchool,
      selectedGroup,
      startDate,
      endDate,
      spanish_id,
    }) => {
      const token =
        this.props.sessionContext.sessionStorageGet("token") ||
        this.props.sessionContext.token ||
        "";

      fetch(
        `${this.props.sessionContext.hostURL}/ETLReport/get_itemanalysis_student?grade_id=${selectedGrade}&quiz_id=${selectedQuiz}&spanish_id=${spanish_id}&assessment_type_id=${quizAssess}&district_id=${selectedDistrict}&state_id=${selectedState}&year_start_date=${startDate}&year_end_date=${endDate}&school_id=${selectedSchool}&group_puid=${selectedGroup}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          responseType: "blob",
          referrer: "no-referrer",
        }
      ).then(async (response) => {
        const filename = `Student_Level_Performance_Report.xlsx`;
        const blob = await response.blob();

        // Download the file
        saveAs(blob, filename);
      });
    },

    getGroupGrades: async (id, groupId) => {
      const { post } = this.props.sessionContext;
      try {
        let res = await post({
          method: "GET",
          url: `${urls.admin.ETLReport.getGroupGrades}?board_id=${id}&group_puid=${groupId}`,
        });

        if (res) {
          if (res.status === "success") {
            this.setState((state) => ({
              ...state,
              etlGradeList: res.data.data,
              etlSchoolYr: res.data.school_year,
            }));
            return res.data;
          } else {
            this.props.sessionContext.showNotification({
              message: res.message,
              type: "error",
            });
          }
        } else {
          throw "Grade List load error";
        }
      } catch (err) {
        throw err;
      }
    },
    getETLQuizzes: async (id, type) => {
      const { post } = this.props.sessionContext;
      try {
        let res = await post({
          method: "GET",
          url: `${urls.admin.ETLReport.getETLQuizzes}?grade_id=${id}&assessment_type_id=${type}`,
        });

        if (res) {
          if (res.status === "success") {
            this.setState((state) => ({
              ...state,
              etlQuizList: res.data,
            }));
            return res.data;
          } else {
            this.props.sessionContext.showNotification({
              message: res.message,
              type: "error",
            });
          }
        } else {
          throw "Grade List load error";
        }
      } catch (err) {
        throw err;
      }
    },
    getAttempts: async (
      gradeId,
      quizId,
      spaId,
      assessType,
      distId,
      stateId,
      startYr,
      endYr,
      schId,
      gpId,
      std,
      userId,
      reqType
    ) => {
      const { post } = this.props.sessionContext;
      try {
        let res = await post({
          method: "GET",
          url: `${urls.admin.ETLReport.getAttempts}?grade_id=${gradeId}&quiz_id=${quizId}&spanish_id=${spaId}&assessment_type_id=${assessType}&district_id=${distId}&state_id=${stateId}&year_start_date=${startYr}&year_end_date=${endYr}&request_type=${reqType}&standard_name=${std}&school_id=${schId}&group_puid=${gpId}&user_puid=${userId}`,
        });

        if (res) {
          if (res.status === "success") {
            return res.data;
          } else {
            this.props.sessionContext.showNotification({
              message: res.message,
              type: "error",
            });
          }
        } else {
          throw "Grade List load error";
        }
      } catch (err) {
        throw err;
      }
    },
    getIndividualLearnosityAssessmentResponse: async (data) => {
      const { post, userData } = this.props.sessionContext;
      try {
        let res = await post({
          method: "GET",
          url: `${urls.reports.getIndividualLearnosityAssessmentResponse}?user_puid=${userData.userPuid}&group_puid=${data.groupId}&student_puid=${data.studId}&assessment_id=${data.quiz_id}&result_id=${data.resultId}&session_id=${data.sessionId}`,
        });

        if (res) {
          if (res.status === "success") {
            return res.data;
          } else {
            this.props.sessionContext.showNotification({
              message: res.message,
              type: "error",
            });
          }
        } else {
          throw "Grade List load error";
        }
      } catch (err) {
        throw err;
      }
    },
    getIndividualLearnosityAssessmentReport: async (data) => {
      const { post, userData, active } = this.props.sessionContext;
      let type =
        data.quiz_type.at(0) == "l" ? data.quiz_type.slice(1) : data.quiz_type;
      try {
        let res = await post({
          method: "GET",
          url: `${
            urls.reports.getIndividualLearnosityAssessmentReport
          }?user_puid=${userData.userPuid}&group_puid=${data.groupId}&id=${
            data.quiz.Id || data?.quiz?.item_id
          }&item_type=${type}`,
        });

        if (res) {
          if (res.status === "success") {
            this.state._setState((state) => ({
              ...state,
              individualAssessmentReport: res.data,
              assessmentId: data.quiz.Id,
              itemType: type,
              selectedAssessment: data.quiz,
            }));
            if (active === "staarReady") {
              this.state._setState((state) => ({
                ...state,
                selectedGroup: data.quiz,
                selectedLearnoItem: data.quiz,
              }));
            }
          } else {
            this.state._setState((state) => ({
              ...state,
              itemType: type,
              individualAssessmentReport: [],
              selectedAssessment: data.quiz,
            }));
            if (active === "staarReady") {
              this.state._setState((state) => ({
                ...state,
                selectedGroup: data.quiz,
                selectedLearnoItem: data.quiz,
              }));
            }
            throw res.message;
          }
        } else {
          throw "Grade List load error";
        }
      } catch (err) {
        throw err;
      }
    },
    getIndividualLearnosityAssessmentAttempts: async (data) => {
      const token =
        this.props.sessionContext.sessionStorageGet("token") ||
        this.props.sessionContext.token ||
        "";
      const { post, userData } = this.props.sessionContext;
      let res = await post({
        url: `${urls.reports.getIndividualLearnosityAssessmentAttempts}?user_puid=${userData.userPuid}&group_puid=${data.groupId}&student_puid=${data.studentId}&assessment_id=${data.quizId}&result_id=${data.resultId}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: undefined,
      });

      if (res) {
        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            learnoAttemptDetails: res.data,
          }));
          if (userData.role === 1) {
            this.state._setState((state) => ({
              ...state,
              individualLearnoObj: {
                groupId: 0,
                studId: userData?.userPuid,
                quiz_id: data.quizId,
                title: data.AssessmentName,
                resultId: res.data[res.data?.length - 1]?.result_id,
                sessionId: res.data[res.data?.length - 1]?.session_id,
              },
            }));
            localStorage.setItem("mygroups-state", JSON.stringify(this.state));
          }
          return res.data;
        } else {
          throw res.message;
        }
      } else {
        throw "Data Load Error";
      }
    },

    getLearnosityItemwiseQuiz: async (payload) => {
      const { post } = this.props.sessionContext;

      try {
        let res = await post({
          method: "GET",
          url: `${urls.reports.getLearnosityItemwiseQuiz}?${qs.stringify(
            payload
          )}`,
          data: undefined,
        });

        if (res) {
          if (res.status === "success") {
            this.state._setState((state) => ({
              ...state,
              itemWiseReport: res.data,
            }));
          } else {
            this.state._setState((state) => ({
              ...state,
              itemWiseReport: [],
            }));
            throw res.message;
          }
        } else {
          throw "Dashboard error";
        }
      } catch (e) {
        console.log("MY-GROUPS ERROR: ", e);
      }
    },

    learnosityExportItemAnalysisReport: async (data, mode) => {
      const token =
        this.props.sessionContext.sessionStorageGet("token") ||
        this.props.sessionContext.token ||
        "";

      let res = fetch(
        `${this.props.sessionContext.hostURL}${urls.reports.learnosityExportItemAnalysisReport}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          responseType: "blob",
          body: JSON.stringify(data),
          referrer: "no-referrer",
        }
      ).then(async (response) => {
        console.log(response.headers.get("content-length"));
        const filename = `${data.rc_header}_Item_Analysis_Report(Student).xlsx`;
        const blob = await response.blob();
        saveAs(blob, filename);
      });
    },

    getNotification: async (payload) => {
      const { post } = this.props.sessionContext;
      const res = await post({
        url: `${urls.notification.getNotification}?${qs.stringify(payload)}`,
        method: "GET",
        data: undefined,
      });

      if (res) {
        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            notificationObj: res.data,
          }));
          return res.data;
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          return false;
        }
      }
    },
    disableNotification: async (payload) => {
      const { post, showNotification } = this.props.sessionContext;
      try {
        let res = await post({
          method: "POST",
          url: `${urls.notification.disableNotification}?${qs.stringify(
            payload
          )}`,
          contentType: "application/json",
          isUrlEncoded: true,
          data: undefined,
        });

        if (res) {
          showNotification({
            message: res.message,
            type: [res.status],
          });
        } else {
          throw "create notification error";
        }
      } catch (e) {
        console.log("MY-GROUPS ERROR: ", e);
      }
    },
    fetchPrintableAttemptData: async (payload) => {
      const { post, showNotification } = this.props.sessionContext;
      try {
        let res = await post({
          method: "GET",
          url: `${urls.reports.fetchPrintableAttemptData}?${qs.stringify(
            payload
          )}`,
          data: undefined,
        });
        if (res?.status === "success") {
          return res.data;
        } else {
          showNotification({
            message: "Unable to load data",
            type: "error",
          });
        }
      } catch (e) {
        console.log("MY-GROUPS ERROR: ", e);
      }
    },
    fetchPrintableResponseData: async (payload) => {
      const { post, showNotification } = this.props.sessionContext;
      try {
        let res = await post({
          method: "GET",
          url: `${urls.reports.fetchPrintableResponseData}?${qs.stringify(
            payload
          )}`,
          data: undefined,
        });

        if (res?.status === "success") {
          return res.data;
        } else {
          showNotification({
            message: "Unable to load data",
            type: "error",
          });
          return [];
        }
      } catch (e) {
        console.log("MY-GROUPS ERROR: ", e);
      }
    },
    fetchAssignedGroups: async (lesson_id) => {
      const { post, userData } = this.props.sessionContext;
      try {
        let res = await post({
          method: "GET",
          url: `${urls.reports.fetchAssignedGroups}?lesson_id=${lesson_id}&user_puid=${userData.userPuid}`,
          data: undefined,
        });
        if (res?.status === "success") {
          this.state._setState((state) => ({
            ...state,
            lessonAssignedGroupList: res.data,
          }));
        } else {
          this.state._setState((state) => ({
            ...state,
            lessonAssignedGroupList: [],
          }));
        }
      } catch (e) {
        console.log("MY-GROUPS ERROR: ", e);
      }
    },
  };

  render() {
    return (
      <MygroupsContext.Provider value={this.state}>
        {this.props.children}
        <Persist
          name="mygroups-state"
          data={this.state}
          debounce={500}
          onMount={(data) => this.setState(data)}
        />
      </MygroupsContext.Provider>
    );
  }
}

export default compose(
  withSessionContext,
  withMygroupsContext
)(MygroupsContextProvider);
