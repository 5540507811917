import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  fetchStaarReadyDataSuccess,
  fetchRCAssessmentDataSuccess,
  fetchStaarPracticeDataSuccess,
  fetchItemDataSuccess,
  fetchStaarResourcesSuccess,
  getGroupAssignmentsSuccess,
} from "../actions/staarReadyActions";
import {
  startSpinner,
  stopSpinner,
  showPopUp,
} from "../../../redux/authActions";
import * as actions from "../actions/actionTypes";
import { doGet, doPost } from "../../../utils/ajaxHelper";
import { url } from "../../../utils/apiEndpoints";

export function* getStaarReadyData(action) {
  try {
    yield put(startSpinner());
    const fetchURL = url.staarready.getUnits;
    const unitsData = yield call(doPost, fetchURL, action.payload);
    // const unitsData = get_staar_units;
    if ([401, "401"].includes(unitsData?.status)) {
      yield put(showPopUp(401));
    } else {
      yield put(fetchStaarReadyDataSuccess(unitsData?.data));
    }
  } catch (e) {
    console.error("Error-->", e);
    yield put(showPopUp(501));
  } finally {
    yield put(stopSpinner());
  }
}

export function* getRCAssessmentData(action) {
  try {
    yield put(startSpinner());
    const fetchURL = `${url.staarready.getRcAssessments}?product_id=${action.payload.product_id}&unit_id=${action.payload.unit_id}&user_puid=${action.payload.user_puid}`;
    const rcData = yield call(doGet, fetchURL, action.payload);
    if ([401, "401"].includes(rcData?.status)) {
      yield put(showPopUp(401));
      yield put(fetchRCAssessmentDataSuccess(null));
    } else {
      yield put(fetchRCAssessmentDataSuccess(rcData?.data));
    }
  } catch (e) {
    console.error("Error from SR reducter-->", e);
    yield put(showPopUp(501));
    yield put(fetchRCAssessmentDataSuccess(null));
  } finally {
    yield put(stopSpinner());
  }
}

export function* getStaarPracticeData(action) {
  try {
    yield put(startSpinner());
    const fetchURL = `${url.staarready.getStaarPractice}?product_id=${action.payload.product_id}&user_puid=${action.payload.user_puid}`;
    const staarData = yield call(doGet, fetchURL, action.payload);
    if ([401, "401"].includes(staarData?.status)) {
      yield put(showPopUp(401));
      yield put(fetchStaarPracticeDataSuccess(null));
    } else {
      yield put(fetchStaarPracticeDataSuccess(staarData?.data));
    }
  } catch (e) {
    console.error("Error from SR reducter-->", e);
    yield put(showPopUp(501));
    yield put(fetchStaarPracticeDataSuccess(null));
  } finally {
    yield put(stopSpinner());
  }
}

export function* fetchItemData(action) {
  try {
    yield put(startSpinner());
    const fetchURL = `${url.staarready.getPathWay}`;
    const itemData = yield call(doPost, fetchURL, action.payload);
    if ([401, "401"].includes(itemData?.status)) {
      yield put(showPopUp(401));
      yield put(fetchItemDataSuccess(null));
    } else {
      yield put(fetchItemDataSuccess(itemData?.data));
    }
  } catch (e) {
    console.error("Error from SR reducter-->", e);
    yield put(showPopUp(501));
    yield put(fetchItemDataSuccess(null));
  } finally {
    yield put(stopSpinner());
  }
}

export function* getStaarReadyResourceData(action) {
  try {
    yield put(startSpinner());
    const fetchURL = `${url.staarready.getResources}`;
    const itemData = yield call(doPost, fetchURL, action.payload);
    console.log("itemData data", itemData);
    if ([401, "401"].includes(itemData?.status)) {
      yield put(showPopUp(401));
      yield put(fetchStaarResourcesSuccess(null));
    } else {
      yield put(fetchStaarResourcesSuccess(itemData?.data));
    }
  } catch (e) {
    console.error("Error from SR reducter-->", e);
    yield put(showPopUp(501));
    yield put(fetchStaarResourcesSuccess(null));
  } finally {
    yield put(stopSpinner());
  }
}
export function* getGroupAssignment(action) {
  try {
    yield put(startSpinner());
    const fetchURL = `${url.staarready.getGroupAssignments}?to_date=&from_date=&user_puid=${action.payload.userPuid}`;
    const staarData = yield call(doGet, fetchURL, action.payload);
    if ([401, "401"].includes(staarData?.status)) {
      yield put(showPopUp(401));
      yield put(getGroupAssignmentsSuccess(null));
    } else {
      yield put(getGroupAssignmentsSuccess(staarData?.data));
    }
  } catch (e) {
    console.error("Error from SR reducter-->", e);
    yield put(showPopUp(501));
    yield put(getGroupAssignmentsSuccess(null));
  } finally {
    yield put(stopSpinner());
  }
}

export function* callGetStaarReadyResourceData() {
  yield takeLatest(actions.GET_STAAR_RESOURCES, getStaarReadyResourceData);
}

export function* callGetStaarReadyData() {
  yield takeLatest(actions.GET_STAAR_READY_DATA, getStaarReadyData);
}

export function* callGetRCTestData() {
  yield takeLatest(actions.GET_RCASSIGNMENT_DATA, getRCAssessmentData);
}

export function* callGetStaarPracticeData() {
  yield takeLatest(actions.GET_STAAR_PRACTICE_DATA, getStaarPracticeData);
}

export function* callFetchItemData() {
  yield takeLatest(actions.GET_ITEMS_DATA, fetchItemData);
}

export function* callGetGroupAssignments() {
  yield takeLatest(actions.GET_GROUP_ASSIGNMENTS, getGroupAssignment);
}

export function* staarReadySaga() {
  yield all([
    callGetStaarReadyResourceData(),
    callGetStaarReadyData(),
    callGetRCTestData(),
    callGetStaarPracticeData(),
    callFetchItemData(),
    callGetGroupAssignments(),
  ]);
}
