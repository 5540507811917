import * as React from "react";
import { Persist } from "react-persist";
import { urls } from "HOC/withApiService";
import compose from "utils/compose";
import { routePaths } from "../containers/Routes/Routes";
import withSessionContext from "HOC/withSessionContext";
import withWorkspaceContext from "HOC/withWorkspaceContext";
const qs = require("query-string");
const ContentContext = React.createContext();

export const ContentContextConsumer = ContentContext.Consumer;

let initialState = {
  page: "Content",
  playlistResources: [],
  renderItemData: {},
  unitData: {},
  itemData: {},
  tab: "sciencelibrary",
  playListNames: [],
  assignmentModalData: {
    groupsData: [],
    selectedCategory: 1,
    selectedClass: "",
    initialUnassignedList: [],
    initialAssignedList: [],
    initialAssignedTakenList: [],
    currentUnassignedList: [],
    currentAssignedList: [],
    fromDate: "",
    toDate: "",
  },
  fullGroupsData: [],
};
const domParse = (data) => {
  let parser = new DOMParser();
  let dom = parser.parseFromString("<!doctype html><body>" + data, "text/html");
  return dom.body.textContent;
};
class ContentContextProvider extends React.Component {
  state = {
    ...initialState,
    showErrorText: "",
    _setState: (next, cb) => {
      if (typeof next === "function") {
        this.setState(
          (state) => next(state),
          () => {
            cb && typeof cb === "function" && cb(this.state);
            console.log("-------new Content context:", this.state);
          }
        );
      } else {
        this.setState({ ...next }, () => {
          cb && typeof cb === "function" && cb(this.state);
          console.log("-------new Content context:", this.state);
        });
      }
    },
    resetAssignmentModalData: () => {
      this.setState((state) => ({
        ...state,
        assignmentModalData: {
          groupsData: [],
          selectedCategory: 1,
          selectedClass: "",
          initialUnassignedList: [],
          initialAssignedList: [],
          initialAssignedTakenList: [],
          currentUnassignedList: [],
          currentAssignedList: [],
          fromDate: "",
          toDate: "",
        },
        fullGroupsData: [],
      }));
    },

    resetState: () => {
      this.setState(
        (state) => ({
          ...initialState,
          // ContentType: state.ContentType //remember this so when Content is complete, operator only has to choose traveler type
        }),
        () => {
          console.log("--------Content context reset:", this.state);
        }
      );
    },
    getClasses: async (assessment_id) => {
      const { post, userData } = this.props.sessionContext;
      const payload = {
        user_puid: userData.userPuid,
        lesson_id: assessment_id,
      };

      let res = await post({
        url: `${urls.lesson.getClasses}?${qs.stringify(payload)}`,
        method: "GET",
        data: undefined,
      });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            classData: res.data,
          }));
          return res.data;
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          // throw res.message;
        }
      } else {
        throw "Delete Lesson Error";
      }
    },
    getFullGroupsData: async (classData, lesson_id) => {
      const groupsData = [];
      const groups = classData;
      await groups.forEach(async (group) => {
        // const subgroupsData = await this.state.getStudentGroups(
        //   group.group_puid,
        //   lesson_id
        // );
        const subgroupsData = null;
        // if (Object.keys(subgroupsData).length > 0) {
        const fullGroup = { ...group, ...subgroupsData };
        groupsData.push(fullGroup);
        this.state._setState((prev) => ({
          ...prev,
          fullGroupsData: [...prev.fullGroupsData, { ...fullGroup }],
        }));
        // }
      });
      return groupsData;
    },
    getStudentGroups: async (groupid, assessment_id) => {
      const { post, userData } = this.props.sessionContext;
      const payload = {
        user_puid: userData.userPuid,
        group_puid: groupid,
        lesson_id: assessment_id,
      };

      let res = await post({
        method: "GET",
        url: `${urls.mygroups.getClassesAndUsers}?${qs.stringify(payload)}`,
        data: undefined,
      });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            studentGroupData: res.data,
          }));
          return res.data;
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "Delete Lesson Error";
      }
    },
    getContentData: async () => {
      const { post, userData } = this.props.sessionContext;
      this.state._setState((state) => ({
        ...state,
        unitData: {},
      }));

      let res = await post({
        method: "GET",
        // url: `${urls.content.getUnits}?user_puid=${userData.userPuid}`,
        url: `${urls.content.getUnits}?user_puid=${userData.userPuid}`,
        // 4aed5926-115a-424d-b19d-bae63f850ec3
        data: undefined,
      });
      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          // if (
          //   this.props.sessionContext.contentGrade &&
          //   this.props.sessionContext.contentGrade.product_id
          // ) {
          //   this.state._setState((state) => ({
          //     ...state,
          //     unitData: {
          //       ...res.data,
          //       default_data: {
          //         ...res.data.default_data,
          //         product_id:
          //           this.props.sessionContext.contentGrade &&
          //           this.props.sessionContext.contentGrade.product_id,
          //       },
          //     },
          //   }));
          // } else {
          this.state._setState((state) => ({
            ...state,
            unitData: res.data,
          }));
          return res.data;
          // }
        } else {
          throw res.error;
        }
      } else {
        throw "Content error";
      }
    },
    getContentURL: async (data) => {
      const { post, userData } = this.props.sessionContext;
      let payload = {
        ...data,
        userPuid: userData.userPuid,
      };
      let res = await post({
        url: `${urls.content.getContentUrlId}`,
        contentType: "application/json",
        isUrlEncoded: true,
        data: payload,
      });
      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          return res.data;
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "success",
          });
          throw res.error;
        }
      } else {
        throw "Content URL error";
      }
    },
    getContentItems: async (data = {}, engProductId) => {
      try {
        this.props.sessionContext.startSpinner();
        const { post, userData } = this.props.sessionContext;
        this.state._setState((state) => ({
          ...state,
          itemData: {},
          renderItemData: {},
        }));
        if (Object.values(data).length === 0) {
          data = {
            product_id: this.state.unitData.default_data.product_id || "null",
            unit_id: this.state.unitData.default_data.unit_id || "null",
            subunit_id: this.state.unitData.default_data.sub_unit_id || "null",
            level3_id: this.state.unitData.default_data.level3_id || "null",
          };
        }
        let prodList = this.state.unitData?.data;
        let prodData = prodList?.find((d) => d.id == data.product_id);
        data.user_puid = userData.userPuid;
        data.is_learnosity_enabled = prodData?.is_learnosity_enabled || false;

        let res = await post({
          method: "GET",
          url: `${urls.content.getItems}?${qs.stringify(data)}`,
          data: undefined,
        });

        if (res) {
          if (res.status === "success") {
            let isLevel3 = false;

            this.state._setState((state) => ({
              ...state,
              itemData: res.data,
              renderItemData: res.data,
              isLearnosityEnabled: prodData?.is_learnosity_enabled,
              staarItemData:
                !this.state.unitData.default_data?.unit_id && res.data,
            }));

            if (
              res.data.categories[0].categories[0] &&
              res.data.categories[0].categories[0].categories
            ) {
              isLevel3 = true;
            } else {
              isLevel3 = false;
            }
            this.state._setState((state) => ({
              ...state,
              isLevel3,
            }));
            this.props.sessionContext._setState((state) => ({
              //TODO remove later when state(tx or fl) is available in login API
              ...state,
              isLevel3,
            }));
            let selectedPrId = data.product_id;
            if (
              !engProductId &&
              this.state.unitData.default_data?.language_id == 102
            ) {
              let mappingObj =
                this.state.unitData.data.find((p) => p.id == data.product_id) ||
                {};
              selectedPrId = mappingObj.mapped_id || mappingObj.id;
            } else {
              selectedPrId = data.product_id;
            }
            // this.props.sessionContext._setState((state) => ({
            //   ...state,
            //   contentGrade: {
            //     product_id: engProductId ? engProductId : selectedPrId,
            //   },
            // }));
          } else {
            this.state._setState((state) => ({
              ...state,
              itemData: {},
              renderItemData: {},
            }));
            this.props.sessionContext.showNotification({
              message: res.message,
              type: "error",
            });
          }
        } else {
          this.state._setState((state) => ({
            ...state,
            itemData: {},
            renderItemData: {},
          }));
          this.props.sessionContext.showNotification({
            message: "Please refresh the page",
            type: "error",
          });
          throw "Content error";
        }
      } catch (err) {
        this.state._setState((state) => ({
          ...state,
          itemData: {},
          renderItemData: {},
        }));
        throw err;
      } finally {
        // this.state._setState((state) => ({
        //   ...state,
        //   loading: false,
        // }));
        this.props.sessionContext.stopSpinner();
      }
    },
    getStaarContentItems: async (data = {}) => {
      try {
        const { post, userData } = this.props.sessionContext;

        let prodList = this.state.unitData?.data;
        let prodData = prodList?.find((d) => d.id == data.product_id);
        data.user_puid = userData.userPuid;
        data.is_learnosity_enabled = prodData?.is_learnosity_enabled || false;

        let res = await post({
          method: "GET",
          url: `${urls.content.getItems}?${qs.stringify(data)}`,
          data: undefined,
        });

        if (res) {
          if (res.status === "success") {
            let isLevel3 = false;

            this.state._setState((state) => ({
              ...state,
              isLearnosityEnabled: prodData?.is_learnosity_enabled,
              staarItemData: res.data,
              renderItemData: res.data,
              showLearnoMessage: true,
            }));
            if (res.data.categories[0].categories[0].categories) {
              isLevel3 = true;
            } else {
              isLevel3 = false;
            }
            this.state._setState((state) => ({
              ...state,
              isLevel3,
            }));
            this.props.sessionContext._setState((state) => ({
              //TODO remove later when state(tx or fl) is available in login API
              ...state,
              isLevel3,
            }));
          } else {
            this.state._setState((state) => ({
              ...state,
              staarItemData: {},
            }));
            this.props.sessionContext.showNotification({
              message: res.message,
              type: "error",
            });
          }
        } else {
          this.state._setState((state) => ({
            ...state,
            staarItemData: {},
          }));
          this.props.sessionContext.showNotification({
            message: "Please refresh the page",
            type: "error",
          });
          throw "Content error";
        }
      } catch (err) {
        this.state._setState((state) => ({
          ...state,
          itemData: {},
          renderItemData: {},
        }));
        throw err;
      } finally {
        // this.state._setState((state) => ({
        //   ...state,
        //   loading: false,
        // }));
      }
    },
    assignAssessment: async (data, prodId) => {
      const { post, userData } = this.props.sessionContext;
      const payload = {
        user_puid: userData.userPuid,
        lesson_id: data.lesson_id,
        group_puid: data.group_puid,
        assignment_no: data.assignment_no,
        assignment_details: JSON.stringify(data.assignment_details),
      };
      let res = await post({
        contentType: "application/json",
        isUrlEncoded: true,
        url: urls.lesson.assignAssignment,
        data: payload,
      });

      if (res) {
        if (res.status === "success") {
          data?.showMsg != false &&
            this.props.sessionContext.showNotification({
              message: res.message,
              type: "success",
            });
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        this.props.sessionContext.showNotification({
          message: "Assign Assignment Error",
          type: "error",
        });
        throw "Assign Assignment Error";
      }
    },
    reAssignAssessment: async (data) => {
      const { post, userData } = this.props.sessionContext;
      const payload = {
        assigned_by_puid: userData?.userPuid,
        reassigned_lessonid: data?.reassigned_lessonid,
        quiz_assessment_id: data?.quiz_assessment_id,
        item_type_id: data?.item_type_id,
        schoolyear: data?.school_year,
        reassigned_students: data?.assigned_students,
        reassign_date: data?.reassign_date,
      };
      let res = await post({
        contentType: "application/json",
        isUrlEncoded: true,
        url: urls.lesson.reAssignAssignment,
        data: payload,
      });

      if (res) {
        if (res.status === "success") {
          data?.showMsg != false &&
            this.props.sessionContext.showNotification({
              message: res.message,
              type: "success",
            });
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        this.props.sessionContext.showNotification({
          message: "Re-Assign Assignment Error",
          type: "error",
        });
        throw "Re-Assign Assignment Error";
      }
    },
    releaseReports: async (data) => {
      const { post, userData } = this.props.sessionContext;
      const payload = {
        released_by_puid: userData?.userPuid,
        quiz_assessment_id: data?.quiz_assessment_id,
        item_type_id: data?.item_type_id,
        grouppuid: data?.group_puid,
        release_date: data?.release_date,
      };
      let res = await post({
        contentType: "application/json",
        isUrlEncoded: true,
        url: urls.lesson.releaseReport,
        data: payload,
      });

      if (res) {
        if (res.status === "success") {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "success",
          });
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        this.props.sessionContext.showNotification({
          message: "release Report API Error",
          type: "error",
        });
        throw "release Report API Error";
      }
    },
    assignGroups: async (data, isTeacherDashboard) => {
      const { post, userData } = this.props.sessionContext;
      const payload = {
        user_puid: userData.userPuid,
        ...data,
      };
      let res = await post({
        contentType: "application/json",
        isUrlEncoded: true,
        url: urls.lesson.assignMultipleClasses,
        data: payload,
      });

      if (res) {
        if (res.status === "success") {
          // if (!isTeacherDashboard) {
          //   await this.state.getContentItems();
          // }

          this.props.sessionContext.showNotification({
            message: res.message,
            type: "success",
          });
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "Assign Assignment Error";
      }
    },
    createLesson: (product_id, type, history, unit_id) => {
      this.props.sessionContext.startSpinner();
      this.props.WorkspaceContext._setState(
        (state) => ({
          ...state,
          lessonUpdateData: {
            ...state.lessonUpdateData,
            resources: this.state.playlistResources.sort(
              (a, b) => a.sort_order - b.sort_order
            ),
            product_id: product_id,
            unit_id: unit_id,
          },
        }),
        async () => {
          try {
            this.state._setState((state) => ({
              ...state,
              // playlistResources:[],
              // playListNames:[]
            }));
            await this.props.WorkspaceContext.getWorkspaceData();
            if (type === "lesson") {
              this.props.sessionContext.stopSpinner();
              history.push(routePaths.workspace.addNewLesson + "/lesson/new");
            } else {
              this.props.sessionContext.stopSpinner();
              history.push(
                routePaths.workspace.addNewLesson + "/assignment/new"
              );
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.props.sessionContext.stopSpinner();
          }
        }
      );
    },
    unAssign: async (data) => {
      const { post } = this.props.sessionContext;

      let res = await post({
        contentType: "application/json",
        isUrlEncoded: true,
        url: urls.lesson.unAssign,
        data: data,
      });

      if (res) {
        if (res.status === "success") {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "success",
          });
          localStorage.setItem("isUnassigned", true);
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "Assign Assignment Error";
      }
    },
    getContent: async (id, isProcUrl) => {
      const { post, userData } = this.props.sessionContext;
      let data = {
        id: id,
        userPuid: userData.userPuid,
        roleId: userData.role,
        districtId: userData.districtID,
        stateId: userData.state_id,
        isProcUrl: isProcUrl,
      };
      let res = await post({
        url: `${urls.content.getContent}`,
        contentType: "application/json",
        isUrlEncoded: true,
        data: data,
      });

      if (res) {
        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            conentURLData: res.data,
            isAssignable: res.data.item_details?.is_assignable,
          }));
          return res.data;
        }
      } else {
        throw "err";
      }
    },
    assessmentOverview: async (assessment_id) => {
      const { post, userData } = this.props.sessionContext;
      let data = {
        user_puid: userData.userPuid,
        assessment_id: assessment_id,
        lesson_resource_tracking_id: this.state.contentTrackId,
        lesson_id: 0,
      };

      let res = await post({
        method: "GET",
        url: `${urls.assessment.assessment_overview}?${qs.stringify(data)}`,
        data: undefined,
      });
      if (res) {
        // res = JSON.parse(res);
        if (res.status === "success") {
          // this.props.sessionContext.showNotification({
          //   message: "success",
          //   type: "success",
          // });
          return res;
        } else if (res.status === "error") {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
        } else {
          throw "error";
        }
      }
    },
    startAssessment: async (data, isLeanosity) => {
      const { post, userData } = this.props.sessionContext;
      const quizId = data;

      try {
        let res = await post({
          url: `${urls.assessment.start_assessment}?assessment_id=${quizId}&user_puid=${userData.userPuid}&lesson_resource_tracking_id=${this.state.contentTrackId}&lesson_id=0&is_learnosity_enabled=${isLeanosity}`,
        });

        if (res) {
          if (res.status === "success") {
            this.state._setState((state) => ({
              ...state,
              startAssessmentData: res,
            }));
          } else {
            throw res.message;
          }
        } else {
          throw "Start Assessment error";
        }
      } catch (e) {
        console.log(e);
        throw "Start Assessment error";
      }
    },
    getPreviewItemAccessed: async (assessment_id) => {
      const { post, userData } = this.props.sessionContext;
      let data = {
        user_puid: userData.userPuid,
      };

      let res = await post({
        method: "GET",
        url: `${urls.preview.getPreviewItemAccessed}?${qs.stringify(data)}`,
        data: undefined,
      });
      if (res) {
        // res = JSON.parse(res);
        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            previewItemData: res.data,
          }));
          return res;
        } else if (res.status === "error") {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
        } else {
          throw "error";
        }
      }
    },
    addExternalResource: async (data, isEdit) => {
      const { post, userData } = this.props.sessionContext;
      data.user_puid = userData.userPuid;
      let updateURL = "";
      if (isEdit) {
        updateURL = urls.content.updateExternalItems;
      } else {
        updateURL = urls.content.addExternalItems;
      }

      let res = await post({
        method: "POST",
        url: `${updateURL}?${qs.stringify(data)}`,
      });
      if (res) {
        // res = JSON.parse(res);
        if (res.status === "success") {
          // this.state.getContentItems();
          this.props.sessionContext.showNotification({
            message: `Resource ${isEdit ? "Updated" : "Added"}`,
            type: "success",
          });
          return res.status;
        } else if (res.status === "error") {
          this.state._setState((state) => ({
            ...state,
            showErrorText: res.message,
          }));
          return res.status;
        } else {
          throw "error";
        }
      }
    },
    addExternalAttachment: async (data) => {
      const { fileUpload } = this.props.sessionContext;

      let res = await fileUpload({
        url: `${urls.content.addExternalAttachment}`,
        data: data,
      });
      if (res) {
        // res = JSON.parse(res);
        if (res.status === "success") {
          // this.state.getContentItems();
          this.props.sessionContext.showNotification({
            message: "Resource Added",
            type: "success",
          });
          return res.status;
        } else if (res.status === "error") {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          return res.status;
        } else {
          throw "error";
        }
      }
    },
    addOrRemoveToMyLibrary: async (data, staarAssessment) => {
      const { post, userData } = this.props.sessionContext;
      let payload = {
        user_puid: userData.userPuid,
        product_id: data.product_id,
        unit_id: data.unit_id,
        subunit_id: data.sub_unit_id,
        level3_id: data.isLevel3 || "null",
        item_id: data.resource_id,
        item_type_id: data.itemTypeId,
      };
      let res;
      if (data.task) {
        res = await post({
          method: "POST",
          url: `${urls.content.addItemsToMyLibrary}?${qs.stringify(payload)}`,
        });

        if (res) {
          if (res.status === "success") {
            if (staarAssessment) {
              const sendData = {
                product_id: this.state.unitData.default_data.product_id,
                unit_id: "null",
                subunit_id: "null",
                level3_id: "null",
              };

              await this.state.getStaarContentItems(sendData);
            }
            this.props.sessionContext.showNotification({
              message: res.message,
              type: "success",
            });
            return true;
          } else {
            this.props.sessionContext.showNotification({
              message: res.message,
              type: "error",
            });
          }
          this.state._setState({
            ...this.state,
            previewData: {
              ...this.state.previewData,
              mylibrary: res.status === "success",
            },
          });
          return false;
          // await this.state.getContentItems();
        }
      } else {
        if (data.external_resource) {
          let removePayload = {
            user_puid: userData.userPuid,
            resource_id: data.resource_id,
          };
          res = await post({
            method: "DELETE",
            url: `${
              urls.content.removeExternalItemFromMylibrary
            }?${qs.stringify(removePayload)}`,
          });
        } else {
          res = await post({
            method: "DELETE",
            url: `${urls.content.removeItemFromMylibrary}?${qs.stringify(
              payload
            )}`,
          });
        }

        if (res) {
          if (res.status == "success") {
            // await this.state.getContentItems();
            if (staarAssessment) {
              const sendData = {
                product_id: this.state.unitData.default_data.product_id,
                unit_id: "null",
                subunit_id: "null",
                level3_id: "null",
              };

              await this.state.getStaarContentItems(sendData);
            }
            this.props.sessionContext.showNotification({
              message: res.message,
              type: "success",
            });
            return true;
          } else {
            this.props.sessionContext.showNotification({
              message: res.message,
              type: "error",
            });
            return false;
          }
        }
      }
    },
    checkResourceCompletion: async (data) => {
      const { post, userData } = this.props.sessionContext;

      try {
        let res = await post({
          url: `${urls.lesson.checkResourceCompletion}?user_puid=${
            userData.userPuid
          }&${qs.stringify(data)}`,
        });

        if (res) {
          // res = JSON.parse(res);
          if (res.status === "success") {
            return res;
          }
          return res;
        } else {
          throw "checkResourceCompletion error";
        }
      } catch (e) {
        console.log(e);
      }
    },
    trackContent: async (data, type) => {
      const { post, userData } = this.props.sessionContext;
      let res;

      data = { ...data, user_puid: userData.userPuid };
      if (type === "start") {
        res = await post({
          method: "POST",
          url: `${urls.content.trackContentStart}?${qs.stringify(data)}`,
        });
      } else {
        res = await post({
          method: "POST",
          url: `${urls.content.trackContentEnd}?${qs.stringify(data)}`,
        });
      }

      if (res) {
        // res = JSON.parse(res);
        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            contentTrackId: res.data,
          }));
        }
      }
    },
    submitQuiz: async (data, isAssessment) => {
      const { post, showNotification } = this.props.sessionContext;
      try {
        let updatedUrl = `${
          isAssessment
            ? urls.assessment.submit_assessment
            : urls.workspace.submitQuiz
        }`;
        let res = await post({
          contentType: "application/json",
          isUrlEncoded: true,
          url: updatedUrl,
          data: data,
        });

        if (res) {
          if (res.status === "success") {
            if (data.type == "save") {
              this.props.sessionContext.showNotification({
                type: "success",
                message: "Responses of Quiz Saved - Successfully",
              });
            } else {
              this.props.sessionContext.showNotification({
                type: "success",
                message: res.message,
              });
            }
          } else {
            this.props.sessionContext.showNotification({
              type: "error",
              message: res.message,
            });
          }
        } else {
          throw "Failed to submit. Please try submitting again";
        }
      } catch (e) {
        this.props.sessionContext.showNotification({
          type: "error",
          message: e,
        });
        console.log(e);
        throw e;
      }
    },
    submitReaderQuiz: async (data) => {
      const { post, userData } = this.props.sessionContext;
      try {
        data = { ...data, UserPuid: userData.userPuid };
        let res;
        res = await post({
          method: "POST",
          url: `${urls.content.ReadersQuizSubmit}`,
          contentType: "application/json",
          isUrlEncoded: true,
          data: data,
        });
        if (res && res.status === "success") {
          // this.setState((state) => ({
          //   ...state,
          // }));
        }
      } catch (error) {
        console.error("Error submitting reader quiz:", error);
      }
    },
    submitItemPrintableQuiz: async (data) => {
      const { post, userData } = this.props.sessionContext;
      try {
        data = { ...data, UserPuid: userData.userPuid };
        let res = await post({
          method: "POST",
          url: urls.content.submitItemPrintableQuiz,
          contentType: "application/json",
          isUrlEncoded: true,
          data: data,
        });
        if (res && res.status === "success") {
        }
      } catch (error) {
        console.error("Error submitting reader quiz:", error);
      }
    },

    createLessonInBackground: async (data, selectedUnit) => {
      console.log("hiii from clbg", data, selectedUnit);
      const { fileUpload, userData } = this.props.sessionContext;
      let lexile = "";
      if (data.type_id == 25) {
        for (var prop in data.url_link) {
          if (data.url_link.hasOwnProperty(prop)) {
            if (data.url_link[prop] === data.url_link.url)
              if (prop === "Lexile2_url") {
                lexile = data.url_link.Lexile2_score;
              } else if (prop === "Lexile1_url") {
                lexile = data.url_link.Lexile1_score;
              } else if (prop === "Lexile3_url") {
                lexile = data.url_link.Lexile3_score;
              } else if (prop === "Spanish_url") {
                lexile = "spanish";
              }
          }
        }
      }
      let newRes = [];
      let resource = {
        item_id: data.id || 0,
        resource_type_id: data.type_id,
        time: 0,
        sort_order: 0,
        remarks: lexile,
      };
      newRes.push(resource);
      let tempData = {
        user_puid: userData.userPuid,
        lesson_name: data.name,
        lesson_objective: data.description,
        instructions: data.instructions,
        total_time: 15,
        due_date: "2020-07-31 00:00:00",
        iscustom_unit_folder: selectedUnit?.unit_id >= 0 ? "no" : null,
        product_id: selectedUnit?.product_id,
        unit_id: selectedUnit?.unit_id,
        iscustom_subunit_folder: selectedUnit?.subunit_id >= 0 ? "no" : null,
        subunit_id: selectedUnit?.subunit_id,
        iscustom_level3_folder: selectedUnit?.level3_id >= 0 ? "no" : null,
        level3_id: selectedUnit?.level3_id,
        lesson_resources: JSON.stringify(newRes),
        is_assignment: true,
      };

      let formData = new FormData();
      formData.append("user_puid", tempData.user_puid);
      formData.append("lesson_name", tempData.lesson_name);
      formData.append("lesson_objective", tempData.lesson_objective);
      formData.append("total_time", tempData.total_time);
      formData.append("due_date", tempData.due_date);
      formData.append("instructions", tempData.instructions);
      formData.append("iscustom_unit_folder", tempData.iscustom_unit_folder);
      formData.append("product_id", tempData.product_id);
      formData.append("unit_id", tempData.unit_id);
      formData.append(
        "iscustom_subunit_folder",
        tempData.iscustom_subunit_folder
      );
      formData.append("subunit_id", tempData.subunit_id);
      formData.append(
        "iscustom_level3_folder",
        tempData.iscustom_level3_folder
      );
      formData.append("level3_id", tempData.level3_id);
      formData.append("lesson_resources", tempData.lesson_resources);
      formData.append("is_assignment", "yes");
      formData.append("enforce_order", false);

      let res = await fileUpload({
        url: `${urls.lesson.createLesson}`,
        data: formData,
      });

      if (res.data) {
        return res.data;
      }
      return null;
    },

    request: async (payload) => {
      const { post } = this.props.sessionContext;

      payload.grade_levels = JSON.stringify(payload.grade_levels);
      payload.primary_use = JSON.stringify(payload.primary_use);

      try {
        let res = await post({
          url: `${urls.preview.scheduleRequest}?${qs.stringify(payload)}`,
        });

        if (res) {
          if (res.status === "success") {
            this.props.sessionContext.showNotification({
              type: "success",
              message: res.message,
            });
          } else {
            // throw res.message;
          }
        } else {
          throw "Dashboard error";
        }
      } catch (e) {
        console.log(e);
      }
    },
    startLearnosityAssessment: async (data) => {
      const { post } = this.props.sessionContext;
      try {
        let res = await post({
          method: "POST",
          url: `${urls.Learnosity.startLearnosityAssessment}`,
          contentType: "application/json",
          isUrlEncoded: true,
          data: data,
        });

        if (res) {
          if (res.status === "success") {
            return res.data;
          } else {
            this.props.sessionContext.showNotification({
              message: res.message,
              type: "error",
            });
            throw res.message;
          }
        } else {
          throw "Learnosity error";
        }
      } catch (e) {
        console.log(e);
      }
    },
    getItemBreakoutList: async (url) => {
      const { post } = this.props.sessionContext;
      let res = await post({
        url: `${urls.itemBreakoutList.get_item_breakout_list}?url=${url}`,
        method: "POST",
        data: undefined,
      });
      if (res) {
        if (res.status === "success") {
          return res;
        } else if (res.status === "error") {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
        } else {
          throw "error";
        }
      }
    },
    adoptionFeedbackInsert: async (data) => {
      const { post } = this.props.sessionContext;
      let res = await post({
        url: `${urls.adoptionFeedbackInsert}`,
        method: "POST",
        data: data,
      });
      if (res) {
        if (res.status === "success") {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "success",
          });
        } else if (res.status === "error") {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
        } else {
          throw "error";
        }
      }
    },
    updateUserOverlay: async (data) => {
      const { post } = this.props.sessionContext;
      let res = await post({
        url: `${urls.updateUserOverlayDetail}`,
        method: "POST",
        data: data,
        contentType: "application/json",
        isUrlEncoded: true,
      });
    },
    getNotification: async (payload) => {
      const { post, navTabDetails, getNavTabs } = this.props.sessionContext;
      const ifConfigEmpty =
        typeof navTabDetails === "object"
          ? Object.keys(navTabDetails).length > 0
            ? navTabDetails.name == null
            : true
          : navTabDetails == null;
      if (ifConfigEmpty) {
        await getNavTabs();
      }

      const res = await post({
        url: `${urls.notification.getNotification}?${qs.stringify(payload)}`,
        method: "GET",
        data: undefined,
      });

      if (res) {
        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            notificationObj: res.data,
          }));
          return res.data;
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          return false;
        }
      }
    },
    disableNotification: async (payload) => {
      const { post, showNotification } = this.props.sessionContext;
      try {
        let res = await post({
          method: "POST",
          url: `${urls.notification.disableNotification}?${qs.stringify(
            payload
          )}`,
          contentType: "application/json",
          isUrlEncoded: true,
          data: undefined,
        });

        if (res) {
          showNotification({
            message: res.message,
            type: [res.status],
          });
        } else {
          throw "create notification error";
        }
      } catch (e) {
        console.log(e);
      }
    },
  };

  render() {
    return (
      <ContentContext.Provider value={this.state}>
        {this.props.children}
        <Persist
          name="Content-state"
          data={this.state}
          debounce={500}
          onMount={(data) => this.setState(data)}
        />
      </ContentContext.Provider>
    );
  }
}

export default compose(
  withWorkspaceContext,
  withSessionContext
)(ContentContextProvider);
