import React, { useEffect, useState } from "react";
import Routes from "../Routes";
import { BrowserRouter as Router } from "react-router-dom";
import SessionProvider from "../../contexts/session";
import WorkspaceContextProvider from "../../contexts/workspace";
import ContentContextProvider from "contexts/content";
import MygroupsContextProvider from "../../contexts/mygroups"
import NoInternet from "./../Routes/PrivateRoutes/NoInternet";
// import { useClearCache } from "react-clear-cache";
const basename = process.env.NODE_ENV === "production" ? "/" : "";

const App = () => {
  const [online, setOnline] = useState(true);
  // const { isLatestVersion, emptyCacheStorage } = useClearCache();
  // useEffect(() => {
  //   console.log(
  //     "<=================================latest cache================================>",
  //     isLatestVersion
  //   );
  //   if (!isLatestVersion) {
  //     console.log(
  //       "<=================================clearing cache================================>"
  //     );
  //     emptyCacheStorage();
  //   }
  // }, []);

  useEffect(() => {
    let timeout = null;
    const updateOnlineStatus = (isOnline) => {
      setOnline(isOnline);
      timeout = setTimeout(() => callApiNow(), 10000);
    };
    const callApiNow = () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      fetch(window.location.origin, { method: "HEAD" })
        .then((res) =>
          updateOnlineStatus(res.status >= 200 && res.status <= 210)
        )
        .catch((err) => updateOnlineStatus(false));
    };
    //callApiNow();
  }, []);

  return (
    <div>
      {online ? (
        <SessionProvider>
          <MygroupsContextProvider>
            <WorkspaceContextProvider>
              <ContentContextProvider>
                <Router basename={basename}>
                  <Routes />
                </Router>
              </ContentContextProvider>
            </WorkspaceContextProvider>
          </MygroupsContextProvider>
        </SessionProvider>
      ) : (
        <NoInternet />
      )}
    </div>
  );
};

export default App;
