//API Actions
export const GET_STAAR_READY_DATA = "GET_STAAR_READY_DATA";
export const GET_STAAR_READY_DATA_SUCCESS = "GET_STAAR_READY_DATA_SUCCESS";

export const GET_STAAR_PRACTICE_DATA = "GET_STAAR_PRATICE_DATA";
export const GET_STAAR_PRACTICE_DATA_SUCCESS =
  "GET_STAAR_PRACTICE_DATA_SUCCESS";

export const GET_RCASSIGNMENT_DATA = "GET_RCASSIGNMENT_DATA";
export const GET_RCASSIGNMENT_DATA_SUCCESS = "GET_RCASSIGNMENT_DATA_SUCCESS";

export const SET_PREVIEW_ITEM_DATA = "SET_PREVIEW_ITEM_DATA";
export const SET_PREVIEW_ITEM_DATA_SUCCESS = "SET_PREVIEW_ITEM_DATA_SUCCESS";

export const GET_ITEMS_DATA = "GET_PATHWAY_DATA";
export const GET_ITEMS_DATA_SUCCESS = "GET_PATHWAY_DATA_SUCCESS";

export const GET_STAAR_RESOURCES = "GET_STAAR_RESOURCES";
export const GET_STAAR_RESOURCES_SUCCESS = "GET_STAAR_RESOURCES_SUCCESS";

export const GET_GROUP_ASSIGNMENTS =  "GET_GROUP_ASSIGNMENTS";
export const GET_GROUP_ASSIGNMENTS_SUCCESS ="GET_GROUP_ASSIGNMENTS_SUCCESS"

//State Update Actions
export const UPDATE_BREADCRUMBS_DATA = "UPDATE_STAARREADY_BREADCRUMBS_DATA";
export const UPDATE_SPINNER_VALUE = "UPDATE_SPINNER_VALUE";
export const UPDATE_DEFAULT_UNIT_DATA = "UPDATE_STAARREADY_DEFAULT_UNIT_DATA";
