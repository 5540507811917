import React from "react";
import { MygroupsContextConsumer } from "contexts/mygroups";

export default function withMygroupsContext(WrappedComponent) {
  class C extends React.Component {
    render() {
      return (
        <MygroupsContextConsumer>
          {MygroupsContext => (
            <WrappedComponent
              {...this.props}
              MygroupsContext={MygroupsContext}         //For any child component the context state will be availible as props
            />
          )}
        </MygroupsContextConsumer>
      );
    }
  }
  C.displayName = `withMygroupsContext(${WrappedComponent.displayName ||
    WrappedComponent.name})`;
  return C;
}
