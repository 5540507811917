import * as actions from "./actionTypes";

export const fetchStaarReadyData = (payload) => ({
  type: actions.GET_STAAR_READY_DATA,
  payload: payload,
});

export const fetchStaarReadyDataSuccess = (payload) => ({
  type: actions.GET_STAAR_READY_DATA_SUCCESS,
  payload: payload,
});

export const fetchStaarPracticeData = (payload) => ({
  type: actions.GET_STAAR_PRACTICE_DATA,
  payload: payload,
});

export const fetchStaarPracticeDataSuccess = (payload) => ({
  type: actions.GET_STAAR_PRACTICE_DATA_SUCCESS,
  payload: payload,
});

export const fetchRCAssessmentData = (payload) => ({
  type: actions.GET_RCASSIGNMENT_DATA,
  payload: payload,
});

export const fetchRCAssessmentDataSuccess = (payload) => ({
  type: actions.GET_RCASSIGNMENT_DATA_SUCCESS,
  payload: payload,
});

export const fetchItemData = (payload) => ({
  type: actions.GET_ITEMS_DATA,
  payload: payload,
});

export const fetchItemDataSuccess = (payload) => ({
  type: actions.GET_ITEMS_DATA_SUCCESS,
  payload: payload,
});

export const fetchStaarResources = (payload) => ({
  type: actions.GET_STAAR_RESOURCES,
  payload: payload,
});

export const fetchStaarResourcesSuccess = (payload) => ({
  type: actions.GET_STAAR_RESOURCES_SUCCESS,
  payload: payload,
});

export const updateDefaultUnitData = (payload) => ({
  type: actions.UPDATE_DEFAULT_UNIT_DATA,
  payload,
});

export const updateBreadCrumbsData = (payload) => ({
  type: actions.UPDATE_BREADCRUMBS_DATA,
  payload: payload,
});

export const getGroupAssignments = (payload)=>({
  type: actions.GET_GROUP_ASSIGNMENTS,
  payload: payload,
})

export const getGroupAssignmentsSuccess = (payload)=>({
  type: actions.GET_GROUP_ASSIGNMENTS_SUCCESS,
  payload: payload,
})