import * as React from "react";
import { urls } from "HOC/withApiService";
import { toast } from "react-toastify";
import { Persist } from "react-persist";
import pdf from "assets/images/pdf-icon.svg";
import word from "assets/images/word-icon.svg";
import google_doc from "assets/images/google-docs-icon.svg";
require("dotenv").config();
const SessionContext = React.createContext();
const qs = require("query-string");
const hostName = window.location.hostname;

export const SessionContextConsumer = SessionContext.Consumer;
let initialState = {
  spinner: 0,
  notifications: [],
  userInfo: null,
  jwt1: "",
  searchText: "",
  navTabDetails: {},
  viewCount: 0,
  isPreviewMode: false,
  active: "scienceLibrary",
  userData: {},
  token: null,
  districts: [],
  contentGrade: null,
  isLoginAsUser: false,
  showInstruction: true,
  isStudentUser: false,
  showPopUps: "",
};

// const makeJwtExpireTime = exp => exp * 1000;
export const itemType = {
  websiteLink: 26,
  youtubeVideo: 27,
};
function makeNotificationType(type) {
  if (type === "INFO") {
    return "is-info";
  } else if (type === "SUCCESS") {
    return "is-success";
  } else if (type === "ERROR") {
    return "is-danger";
  } else {
    return type;
  }
}

/**
 * -1: No Access
 * 0:   Guest
 * 1:   Student
 * 2:   Teacher
 * 6:   Edusmart Administrator
 * 9:   School Administrator
 * 12:  District Administrator
 * 15:  Super Administrator
 */

export const userTypes = {
  "-1": "NO_ACCESS",
  0: "GUEST",
  6: "SCHOOL_ADMINISTRATOR",
  9: "DISTRICT_ADMINISTRATOR",
  12: "EDUSMART_ADMINISTRATOR",
  15: "SUPER_ADMINISTRATOR",
  2: "TEACHER",
  1: "STUDENT",
};

export const fileTypeIcons = { pdf: pdf, word: word, google_docs: google_doc };

class SessionProvider extends React.Component {
  state = {
    ...initialState,
    showSpanText: "",
    _setState: (next, cb) => {
      if (typeof next === "function") {
        this.setState(
          (state) => next(state),
          () => {
            cb && cb(this.state);
            if (
              !process.env.NODE_ENV ||
              process.env.NODE_ENV === "development"
            ) {
              console.log("-------new session state:", this.state);
            }
          }
        );
      } else {
        this.setState(
          (state) => ({
            ...next,
          }),
          () => {
            cb && cb(this.state);
            if (
              !process.env.NODE_ENV ||
              process.env.NODE_ENV === "development"
            ) {
              console.log("-------new session state:", this.state);
            }
          }
        );
      }
    },
    checkForQuestionMark: (url) => {
      if (url?.indexOf("?") >= 0) {
        return true;
      } else {
        return false;
      }
    },
    setActiveTeacher: (nav) => {
      this.state._setState((state) => ({
        ...state,
        active: nav,
      }));
    },

    startSpinner: (callback) => {
      this.setState(
        (state) => ({
          ...state,
          spinner: 1,
        }),
        () => {
          callback && callback();
        }
      );
    },
    timeDiff: (starTime, endTime) => {
      let startTimeSec = new Date(starTime);
      let endTimeSec = new Date(endTime);
      return Math.round((endTimeSec - startTimeSec) / 1000);
    },
    convertTimestamp: (timestamp) => {
      var d = new Date(timestamp * 1000),
        yyyy = d.getFullYear(),
        mm = ("0" + (d.getMonth() + 1)).slice(-2),
        dd = ("0" + d.getDate()).slice(-2),
        hh = d.getHours(),
        h = hh,
        min = ("0" + d.getMinutes()).slice(-2),
        time;
      time = yyyy + "-" + mm + "-" + dd + " " + h + ":" + min + " ";
      return time;
    },
    removeQuotes: (a) => {
      let splitted = a.split("");
      if (splitted[0] === '"' && splitted[splitted.length - 1] === '"') {
        return a.slice(1, -1);
      }
      return a;
    },
    domParse: (data) => {
      let parser = new DOMParser();
      let dom = parser.parseFromString(
        "<!doctype html><body>" + data,
        "text/html"
      );
      return dom.body.textContent;
    },
    monitorReauthentication: () => {
      const { userInfo } = this.state;

      let currTime = new Date();
      let expTime = new Date(userInfo && userInfo.exp * 1000);
      let diffMs = expTime - currTime - 80000; // milliseconds between now & exptime
      // let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      // let timeInterval = (diffMins - 8) * 60000; // call reauthentication 1 mins before expiry
      setTimeout(async () => {
        const { userData } = this.state;
        const data = { user_puid: userData.userPuid };
        const res = await this.state.post({
          url: urls.refreshToken,
          data: data,
          contentType: "application/json",
          isUrlEncoded: true,
        });

        if (res) {
          this.state.sessionStorageSet("token", res.data && res.data.token);
          // const userId = userData?.userPuid;
          localStorage.setItem(`${hostName}-token`, res?.data?.token);
          let decodedJWT = this.state.parseJwt(res.data && res.data.token);
          this.state._setState(
            {
              ...this.state,
              token: res.data && res.data.token,
              userInfo: decodedJWT,
            },
            () => {
              this.state.monitorReauthentication();
            }
          );
          // this.state.showNotification({ message: res, type: "success" });
        } else {
          throw "reauthentication error";
        }
      }, diffMs);
    },

    stopSpinner: () => {
      let spinner = this.state.spinner;
      let nextState = --spinner;
      if (nextState < 0) {
        nextState = 0;
      }
      this.setState((state) => ({
        ...state,
        spinner: nextState,
      }));
    },
    // convertToUTC: UNIX_timestamp => {
    //   let updatedDate = new Date(UNIX_timestamp * 1000).toUTCString();
    //   updatedDate = updatedDate.substring(4).slice(0, -7);
    //   // let newTime=updatedDate.slice(4)
    //   return updatedDate;
    // },
    timeConverter: (UNIX_timestamp) => {
      if (!UNIX_timestamp) {
        return {
          date: "NA",
          time: "NA",
          monthName: "NA",
          singleDate: "NA",
        };
      }
      var a = new Date(UNIX_timestamp * 1000);
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var year = a.getFullYear();
      var month = months[a.getMonth()];
      var date = a.getDate();
      var hour = a.getHours();
      var min = a.getMinutes();
      // var sec = a.getSeconds();
      var finalDate = month + " " + date + ", " + year;
      let finalTime = hour + ":" + min;
      return {
        date: finalDate,
        time: finalTime,
        monthName: month,
        singleDate: date,
      };
    },
    getHostName: () => {
      if (window.location.hostname === "lms2.edusmart.com") {
        return "lms.edusmart.com";
      } else {
        return window.location.hostname;
      }
    },
    showNotification: ({ message, type }) => {
      switch (type) {
        case "success":
          toast.success(message, {
            autoClose: 2400,
          });
          break;
        case "warning":
          toast.warning(message, {
            autoClose: 2400,
          });
          break;
        case "info":
          toast.info(message, {
            autoClose: 5000,
          });
        case "error":
          toast.error(message, {
            autoClose: 2400,
          });
        // default:
        //   toast.success(message);
      }
    },

    removeNotification: (id) => {
      this.setState((state) => ({
        notifications: state.notifications.filter((n) => n.id !== id),
      }));
    },

    addNotification: ({
      id = Math.random().toString(),
      timeOutInMillis = 3000,
      type = "is-warning",
      message = "",
    }) => {
      message = typeof message === "string" ? message : JSON.stringify(message);
      type = makeNotificationType(type);
      const newNotifications = [
        ...this.state.notifications,
        { id, type, message, timeOutInMillis },
      ];
      this.setState({
        notifications: newNotifications,
      });
    },

    sessionStorageSet: (key, val) => {
      try {
        sessionStorage.setItem(key, val);
      } catch (e) {
        console.error("-------- failed to set to session storage:", e);
      }
    },
    resetState: () => {
      this.setState((state) => ({
        ...initialState,
      }));
    },

    sessionStorageGet: (key) => {
      try {
        if (sessionStorage.getItem(key)) {
          // return JSON.parse(sessionStorage.getItem(key));

          return sessionStorage.getItem(key);
        }
        return null;
      } catch (e) {
        console.error("--------- failed to get from session storage:", e);
      }
    },
    //For manual secure sync
    postQuizSmart: ({ url, data, contentType, method, isUrlEncoded }) => {
      if (
        !process.env.NODE_ENV ||
        process.env.NODE_ENV === "development" //console log only in case of development
      ) {
        console.group("--------- calling api ----------");
        console.log("url:", url);
        console.log("data:", data);
        console.log("content type:", contentType);
        console.groupEnd();
      }
      const token =
        localStorage.getItem(`${hostName}-token`) ||
        sessionStorage.getItem("token") ||
        "";
      let headers = {
        "Content-Type": contentType || "application/x-www-form-urlencoded",
      };
      if (token) headers["Authorization"] = `Bearer ${token}`;
      return fetch(`${this.state.quizSmartURL}${url}`, {
        body: data
          ? isUrlEncoded
            ? JSON.stringify(data)
            : qs.stringify(data)
          : undefined, // must match 'Content-Type' header
        headers,
        method: method || "POST", // *GET, POST, PUT, DELETE, etc.
        referrer: "no-referrer", // *client, no-referrer
      })
        .then((res) => {
          if (res.status && res.status !== 200) {
            if (res.status === 401) {
              this.state._setState({ token: null, showPopUps: 401 });
              localStorage.setItem(`${hostName}-token`, null);
            }
            throw res;
          }
          return res.json().catch((err) => {
            return res;
          });
        })
        .then((res) => {
          if (
            !process.env.NODE_ENV ||
            process.env.NODE_ENV === "development" //console log only in case of development
          ) {
            console.log("<=================res===============>", res);
          }
          this.state._setState({ showPopUps: "" });
          return res;
        })
        .catch((e) => {
          if (e.status !== 400)
            this.state._setState({ showPopUps: e?.status || 501 });
          throw e.message || e.statusText || "unknown post error";
        });
    },

    postSync: ({ url, data, contentType, method, isUrlEncoded }) => {
      if (
        !process.env.NODE_ENV ||
        process.env.NODE_ENV === "development" //console log only in case of development
      ) {
        console.group("--------- calling api ----------");
        console.log("url:", url);
        console.log("data:", data);
        console.log("content type:", contentType);
        console.groupEnd();
      }
      const { userData } = this.state;
      // const userId = userData?.userPuid;
      const token =
        localStorage.getItem(`${hostName}-token`) ||
        sessionStorage.getItem("token") ||
        "";
      let headers = {
        "Content-Type": contentType || "application/x-www-form-urlencoded",
      };
      if (token) headers["Authorization"] = `Bearer ${token}`;

      return fetch(`${this.state.hostURL1}${url}`, {
        body: data
          ? isUrlEncoded
            ? JSON.stringify(data)
            : qs.stringify(data)
          : undefined, // must match 'Content-Type' header
        headers,
        method: method || "POST", // *GET, POST, PUT, DELETE, etc.
        referrer: "no-referrer", // *client, no-referrer
      })
        .then((res) => {
          if (res.status && res.status !== 200) {
            if (res.status === 401) {
              this.state._setState({ token: null });
              localStorage.setItem(`${hostName}-token`, null);
            }
            throw res;
          }
          return res.json().catch((err) => {
            return res;
          });
        })
        .then((res) => {
          if (
            !process.env.NODE_ENV ||
            process.env.NODE_ENV === "development" //console log only in case of development
          ) {
            console.log("<=================res===============>", res);
          }
          this.state._setState({ showPopUps: "" });
          return res;
        })
        .catch((e) => {
          if (e.status !== 400)
            this.state._setState({ showPopUps: e?.status || 501 });
          throw e.message || e.statusText || "unknown post error";
        });
    },
    //////////////////////////////
    post: ({ url, data, contentType, method, isUrlEncoded }) => {
      if (
        !process.env.NODE_ENV ||
        process.env.NODE_ENV === "development" //console log only in case of development
      ) {
        console.group("--------- calling api ----------");
        console.log("url:", url);
        console.log("data:", data);
        console.log("content type:", contentType);
        console.groupEnd();
      }
      const { userData } = this.state;
      // const userId = userData?.userPuid;
      const token =
        localStorage.getItem(`${hostName}-token`) ||
        sessionStorage.getItem("token") ||
        "";
      let headers = {
        "Content-Type": contentType || "application/x-www-form-urlencoded",
      };
      if (token) headers["Authorization"] = `Bearer ${token}`;
      if (url === urls.getDistricts) {
        console.log("clnk DT001");
      } else {
        console.log("clnk NDT001");
      }
      return fetch(`${this.state.hostURL}${url}`, {
        body: data
          ? isUrlEncoded
            ? JSON.stringify(data)
            : qs.stringify(data)
          : undefined, // must match 'Content-Type' header
        headers,
        method: method || "POST", // *GET, POST, PUT, DELETE, etc.
        referrer: "no-referrer", // *client, no-referrer
      })
        .then((res) => {
          if (res.status && res.status !== 200) {
            if (res.status === 401) {
              this.state._setState({ token: null, showPopUps: 401 });
              localStorage.setItem(`${hostName}-token`, null);
            }
            throw res;
          }
          return res.json().catch((err) => {
            return res;
          });
        })
        .then((res) => {
          if (
            !process.env.NODE_ENV ||
            process.env.NODE_ENV === "development" //console log only in case of development
          ) {
            console.log("<=================res===============>", res);
          }
          this.state._setState({ showPopUps: "" });
          return res;
        })
        .catch((e) => {
          this.state.stopSpinner();
          if (e.status !== 400)
            this.state._setState({ showPopUps: e?.status || 501 });
          if (e.statusText) {
            throw e.statusText;
          } else if (e.message) {
            if (
              e.message == "Failed to fetch" &&
              this.state.ShowNoInternetErrorMessage
            ) {
              console.log("first");
            } else {
              throw e.message;
            }
          } else {
            console.log("e", e);
          }
        });
    },
    fileUpload: ({ url, data, method, isUrlEncoded }) => {
      if (
        !process.env.NODE_ENV ||
        process.env.NODE_ENV === "development" //console log only in case of development
      ) {
        console.group("--------- calling api ----------");
        console.log("url:", url);
        console.log("data:", data);
        console.groupEnd();
      }
      const { userData } = this.state;
      // const userId = userData?.userPuid;
      const token =
        localStorage.getItem(`${hostName}-token`) ||
        sessionStorage.getItem("token") ||
        "";
      let headers = {};
      if (token) headers["Authorization"] = `Bearer ${token}`;

      return fetch(`${this.state.hostURL}${url}`, {
        body: data,

        headers,
        method: method || "POST", // *GET, POST, PUT, DELETE, etc.
        referrer: "no-referrer", // *client, no-referrer
      })
        .then((res) => {
          if (res.status && res.status !== 200) {
            if (res.status === 401) {
              this.state._setState({ token: null, showPopUps: 401 });
              localStorage.setItem(`${hostName}-token`, "");
            }
            throw res;
          }
          return res.json().catch((err) => {
            return res;
          });
        })
        .then((res) => {
          if (
            !process.env.NODE_ENV ||
            process.env.NODE_ENV === "development" //console log only in case of development
          ) {
            console.log("<=================res===============>", res);
          }
          this.state._setState({ showPopUps: "" });
          return res;
        })
        .catch((e) => {
          if (e.status !== 400)
            this.state._setState({ showPopUps: e?.status || 501 });
          throw e.message || e.statusText || "unknown post error";
        });
    },

    get: (url) => {
      return fetch(`${this.state.hostURL}${url}`)
        .then((res) => {
          if (res.status && res.status !== 200) {
            if (res.status === 401 && res.statusText === "Invalid JWT Token") {
              this.state._setState({ token: null, showPopUps: 401 });
              localStorage.setItem(`${hostName}-token`, null);
            }
            throw res;
          } else {
            if (
              !process.env.NODE_ENV ||
              process.env.NODE_ENV === "development" //console log only in case of development
            ) {
              console.log("<=================res===============>", res);
            }
            this.state._setState({ showPopUps: "" });
            return res;
          }
        })
        .catch((e) => {
          console.error("--------api get:", e);
          if (e.status !== 400)
            this.state._setState({ showPopUps: e?.status || 501 });
          throw e.message || e.statusText || "unknown get error";
        });
    },

    parseJwt: (token) => {
      if (token) {
        let base64Url = token.split(".")[1];
        let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        return JSON.parse(window.atob(base64));
      }
    },

    getNavTabs: async (userPuid) => {
      const { get, userData } = this.state;
      const res = await get(
        `${urls.navTabsDetails}?userpuid=${userPuid ?? userData.userPuid}`
      );
      try {
        const data = await res.json();
        console.log("amama", data.data);
        this.state._setState({
          ...this.state,
          navTabDetails: data.data,
        });
      } catch (e) {
        this.state._setState({
          ...this.state,
          navTabDetails: {},
        });
      }
    },

    login: async (data) => {
      try {
        const res = await this.state.post({
          contentType: "application/json",
          isUrlEncoded: true,
          url: urls.login,
          data,
        });

        if (res) {
          if (res.status === "success") {
            this.state.sessionStorageSet("token", res.data.token);
            // const userId = res?.data?.userPuid;
            localStorage.setItem(`${hostName}-token`, res.data.token);
            localStorage.setItem(
              `${hostName}-hostUrl`,
              `${this.state.hostURL}`
            );
            localStorage.setItem("searchUrl", `${this.state.searchUrl}`);
            localStorage.setItem("isInstruction", true);
            let decodedJWT = this.state.parseJwt(res.data.token);
            this.state._setState({
              ...this.state,
              token: res.data.token,
              userInfo: decodedJWT,
              userData: res.data,
              isPalmDistrictStudent:
                res.data?.districtID == 25251 && res.data?.role == 1,
              isLoginAsUser: false,
              isJustLogged: true,
            });
            return res;
          } else {
            this.state._setState((state) => ({
              ...state,
              showSpanText: res.message,
            }));
          }
        } else {
          throw "login error";
        }
      } catch (err) {
        this.state.showNotification({
          message: "Failed to Login",
          type: "error",
        });
        throw err;
      }
    },
    ssoLogin: async (data) => {
      try {
        sessionStorage.clear();
        localStorage.clear();
        const res = await this.state.post({
          contentType: "application/json",
          isUrlEncoded: true,
          url: urls.sslogin.ssoLogin,
          data,
        });

        if (res) {
          if (res.Message) {
            this.state.showNotification({
              type: "error",
              message: res.Message,
            });
            throw res.Message;
          }
          this.state.sessionStorageSet("token", res.data.token);
          // const userId = res?.data?.userPuid;
          localStorage.setItem(`${hostName}-token`, res.data.token);
          localStorage.setItem(`${hostName}-hostUrl`, `${this.state.hostURL}`);
          localStorage.setItem("searchUrl", `${this.state.searchUrl}`);
          localStorage.setItem("isInstruction", true);
          let decodedJWT = this.state.parseJwt(res.data.token);
          this.state._setState({
            ...this.state,
            token: res.data.token,
            userInfo: decodedJWT,
            userData: res.data,
            isLoginAsUser: false,
            isJustLogged: true,
          });
          this.state.stopSpinner();
        } else {
          throw "login error";
        }
      } catch (err) {
        this.state.showNotification({
          message: "Invalid Credentials",
          type: "error",
        });
        throw err;
      }
    },
    getSchoolList: async (data) => {
      const { post, userData } = this.state;
      this.state._setState((state) => ({
        ...state,
        schoolList: [],
      }));

      try {
        let res = await post({
          method: "GET",
          url: `${urls.admin.user.getAllSchools}?district_id=${userData.districtID}&logged_in_user_puid=${userData.userPuid}`,
        });

        if (res) {
          // res = JSON.parse(res);

          if (res.status === "success") {
            this.state._setState((state) => ({
              ...state,
              schoolList: res.data,
            }));
            return res.data;
          } else {
            this.state._setState((state) => ({
              ...state,
              schoolList: [],
            }));
            this.state.showNotification({
              message: res.message,
              type: "error",
            });
          }
        } else {
          this.state._setState((state) => ({
            ...state,
            schoolList: {},
            // renderItemData: {},
          }));

          throw "School List load error";
        }
      } catch (err) {
        this.state._setState((state) => ({
          ...state,
          schoolList: {},
          // renderItemData: {},
        }));
        throw err;
      } finally {
        this.state._setState((state) => ({
          ...state,
          loading: false,
        }));
      }
    },
    getUserOverlayDetails: async () => {
      const { post, userData } = this.state;
      try {
        const res = await post({
          url: `${urls.getUserOverlayDetail}?user_puid=${userData.userPuid}`,
          method: "GET",
          data: undefined,
        });
        if (res && res.status === "success") {
          const responseDataString = JSON.stringify(res);
          localStorage.setItem("overlayDetails", responseDataString);
        }
      } catch (err) {
        throw err;
      }
    },
    updateUserDetails: async (data) => {
      const { post } = this.state;
      try {
        let res = await post({
          method: "POST",
          contentType: "application/json",
          isUrlEncoded: true,
          url: `${urls.ExternalToolSetUp.updateUserDetail}`,
          data: data,
        });

        if (res) {
          if (res.status === "success") {
            // this.state._setState((state) => ({
            //   ...state,
            //   shareLessonData: res.data,
            // }));
            return res.data;
          } else {
            this.props.sessionContext.showNotification({
              message: res.message,
              type: "error",
            });
            throw res.message;
          }
        } else {
          throw "Dashboard error";
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    getGrades: async () => {
      const { post } = this.state;

      try {
        let res = await post({
          method: "GET",
          url: `${urls.admin.getGrades}`,
        });

        if (res) {
          // res = JSON.parse(res);
          if (res.status === "success") {
            this.state._setState((state) => ({
              ...state,
              gradesList: res.data,
            }));
          } else {
            this.state._setState((state) => ({
              ...state,
              gradseList: {},
            }));
            this.state.showNotification({
              message: res.message,
              type: "error",
            });
          }
        } else {
          this.state._setState((state) => ({
            ...state,
            gradesList: {},
          }));

          throw "Grade List load error";
        }
      } catch (err) {
        this.state._setState((state) => ({
          ...state,
          gradesList: {},
        }));
        throw err;
      }
    },
    retrievePassword: async (data) => {
      try {
        const res = await this.state.post({
          contentType: "application/json",
          isUrlEncoded: true,
          url: urls.retrievePassword,
          data,
        });

        if (res) {
          if (res.status === "success") {
            this.state.showNotification({
              type: "success",
              message: res.message,
            });
          } else {
            this.state.showNotification({
              type: "error",
              message: res.message,
            });
            throw res.Message;
          }
        }
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    loginAsUser: async (data) => {
      const { userData } = this.state;
      data.logged_in_user_puid = userData.userPuid;
      try {
        const res = await this.state.post({
          contentType: "application/json",
          isUrlEncoded: true,
          url: urls.admin.user.loginAsUser,
          data,
        });

        if (res.status == "success") {
          this.state.resetState();
          this.state.sessionStorageSet("token", res.data.token);
          // const userId = res?.data?.userPuid;
          localStorage.setItem(`${hostName}-token`, res.data.token);
          localStorage.setItem(`${hostName}-hostUrl`, `${this.state.hostURL}`);
          localStorage.setItem("searchUrl", `${this.state.searchUrl}`);
          localStorage.setItem("isInstruction", true);
          let decodedJWT = this.state.parseJwt(res.data.token);
          this.state._setState({
            ...this.state,
            token: res.data.token,
            userInfo: decodedJWT,
            userData: res.data,
            isPalmDistrictStudent:
              res.data?.districtID == 25251 && res.data?.role == 1,
            isLoginAsUser: true,
            isJustLogged: true,
          });
          return res.data;
        } else {
          throw "login error";
        }
      } catch (err) {
        this.state.showNotification({
          message: "Invalid Credentials",
          type: "error",
        });
        throw err;
      }
    },
    getUserInfo: async (data) => {
      const res = await this.state.post({
        url: `${urls.getUserInfo}?user_puid=${data}`,
      });

      if (res) {
        let parsedRes = JSON.parse(res);
        this.state._setState((state) => ({
          ...state,
          userData: parsedRes.data,
        }));
      }
    },
    getDistricts: async () => {
      const res = await this.state.post({
        url: `${urls.getDistricts}`,
        method: "GET",
        data: undefined,
      });

      if (res) {
        this.setState((state) => ({
          ...this.state,
          districts: res.data,
        }));
        return res;
      }
    },
    //-----start of clever sso code --------------
    getAccessToken: async (data) => {
      try {
        const res = await this.state.post({
          contentType: "application/json",
          isUrlEncoded: true,
          url: urls.getAccessToken,
          data,
        });
        if (res) {
          console.log("manas", res);
          if (res.data) {
            window.location.href = res.data;
            this.setState({
              ...this.state,
              loading: true,
            });
          }
          if (res.message) {
            this.state.showNotification({
              type: "error",
              message: res.message,
            });
          }
        }
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    getSSOSource: async (data) => {
      try {
        const res = await this.state.post({
          contentType: "application/json",
          isUrlEncoded: true,
          url: urls.getSSOSource,
          data,
        });

        if (res) {
          return res;
        }
        this.setState({
          ...this.state,
          loading: true,
        });
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    CleverSSOLogin: async (data) => {
      try {
        console.log("enter clever login");
        const res = await this.state.post({
          contentType: "application/json",
          isUrlEncoded: true,
          url: urls.cleverssoLogin,
          data,
        });

        if (res) {
          if (res.status === "success") {
            this.state.sessionStorageSet("token", res.data.token);
            // const userId = res?.data?.userPuid;
            localStorage.setItem(`${hostName}-token`, res.data.token);
            localStorage.setItem(
              `${hostName}-hostUrl`,
              `${this.state.hostURL}`
            );
            localStorage.setItem("searchUrl", `${this.state.searchUrl}`);
            localStorage.setItem("isInstruction", true);
            let decodedJWT = this.state.parseJwt(res.data.token);
            this.state._setState({
              ...this.state,
              token: res.data.token,
              userInfo: decodedJWT,
              userData: res.data,
              isLoginAsUser: false,
              isJustLogged: true,
            });
            return res;
          } else {
            this.state.showNotification({
              message: res.message,
              type: "error",
            });
          }
        } else {
          throw "login error";
        }
      } catch (err) {
        this.state.showNotification({
          message: "Failed to Login",
          type: "error",
        });
        throw err;
      }
    },
    //---------------end of cleversso code --------
    signout: () => {
      //this.props.history.push("/");
      if (!this.state.isLoginAsUser) {
        localStorage.clear();
        sessionStorage.clear();
        this.setState({
          ...initialState,
        });
      }
    },
    setActive: (type) => {
      this.state._setState((state) => ({
        ...state,
        studentNavState: type,
      }));
    },

    validateEmail: async (email) => {
      const res = await this.state.post({
        url: `${urls.validateEmail}?emailid=${email}`,
        method: "GET",
      });
      if (res) {
        if (res.status == "success") {
          this.state._setState({
            ...this.state,
            userList: res.data,
          });
        } else {
          this.state._setState({
            ...this.state,
            userList: null,
          });
        }
        return res;
      }
    },

    generateResetLink: async (data) => {
      const res = await this.state.post({
        url: urls.generateResetLink,
        method: "POST",
        contentType: "application/json",
        isUrlEncoded: true,
        data,
      });
      if (res) {
        this.state.showNotification({
          message: res.message,
          type: res.status,
        });
        return res;
      }
    },

    validateResetLink: async (id, props) => {
      const res = await this.state.post({
        url: `${urls.validateResetLink}?Id=${id}`,
        method: "GET",
      });
      if (res.status == "success") {
        return res;
      } else {
        props.history.push("/");
        this.state.showNotification({
          message: res.message,
          type: "error",
        });
      }
    },

    resetPassword: async (data, props) => {
      const res = await this.state.post({
        url: urls.resetPassword,
        method: "POST",
        contentType: "application/json",
        isUrlEncoded: true,
        data,
      });

      if (res.status == "success") {
        this.state.showNotification({
          message: res.message,
          type: "success",
        });
        props.history.push("/");
      } else {
        return res;
      }
    },

    getETLGrades: async (id) => {
      try {
        let res = await this.state.post({
          method: "GET",
          url: `${urls.admin.ETLReport.getETLGrades}?board_id=${id}`,
        });

        if (res) {
          if (res.status === "success") {
            this.setState((state) => ({
              ...state,
              etlGradeList: res.data.data,
            }));
            return res.data;
          } else {
            this.state.showNotification({
              message: res.message,
              type: "error",
            });
          }
        } else {
          throw "Grade List load error";
        }
      } catch (err) {
        throw err;
      }
    },

    sendFeedback: async (data) => {
      const { fileUpload, showNotification } = this.state;
      try {
        let res = await fileUpload({
          url: `${urls.admin.user.sendFeedback}`,
          data: data,
        });

        if (res) {
          if (res.status === "success") {
            showNotification({
              message: res.message || "Feedback sent successfully",
              type: "success",
            });
          } else {
            showNotification({
              message: res.message || "Cannot send feedback",
              type: "error",
            });
            throw res.message;
          }
        } else {
          throw "Send Feedback error";
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    classLinkLogin: async (data) => {
      try {
        const conf = await fetch("/config.json").then((r) => r.json());
        const res = await this.state.post({
          contentType: "application/json",
          isUrlEncoded: true,
          url: urls.classlinklogin,
          data,
        });

        if (res) {
          if (res.status === "success") {
            this.state.sessionStorageSet("token", res.data.token);
            localStorage.setItem(`${hostName}-token`, res.data.token);
            localStorage.setItem(
              `${hostName}-hostUrl`,
              `${this.state.hostURL}`
            );
            localStorage.setItem("searchUrl", `${this.state.searchUrl}`);
            let decodedJWT = this.state.parseJwt(res.data.token);
            this.state._setState(
              {
                ...this.state,
                token: res.data.token,
                userInfo: decodedJWT,
                userData: res.data,
                hostURL: `${conf.REACT_APP_DOMAIN}`,
                hostURL1: `${conf.REACT_APP1_DOMAIN}`,
              },
              () => {
                console.log("clnk state after successful login");
                const sessionStateData = JSON.parse(
                  JSON.stringify(localStorage.getItem("session-state"))
                );
                localStorage.setItem("session-state", {
                  ...sessionStateData,
                  token: res.data.token,
                  userInfo: decodedJWT,
                  userData: res.data,
                  hostURL: `${conf.REACT_APP_DOMAIN}`,
                  hostURL1: `${conf.REACT_APP1_DOMAIN}`,
                });
              }
            );
            const sessionStateData = JSON.parse(
              JSON.stringify(localStorage.getItem("session-state"))
            );
            localStorage.setItem("session-state", {
              ...sessionStateData,
              token: res.data.token,
              userInfo: decodedJWT,
              userData: res.data,
              hostURL: `${conf.REACT_APP_DOMAIN}`,
              hostURL1: `${conf.REACT_APP1_DOMAIN}`,
            });
            return res;
          } else {
            this.state.showNotification({
              message: res.message,
              type: "error",
            });
          }
        } else {
          throw "login error";
        }
      } catch (err) {
        this.state.showNotification({
          message: "Failed to Login",
          type: "error",
        });
        throw err;
      }
    },
  };
  async componentDidMount() {
    let hostURL, hostURL1, quizSmartURL, ShowNoInternetErrorMessage, searchUrl;
    const conf = await fetch("/config.json").then((r) => r.json());

    if (conf) {
      hostURL = `${conf.REACT_APP_DOMAIN}`;
      hostURL1 = `${conf.REACT_APP1_DOMAIN}`;
      quizSmartURL = `${conf.REACT_QuizSmart_DOMAIN}`;
      searchUrl = `${conf.SEARCH_APP_DOMAIN}`;
      ShowNoInternetErrorMessage = `${conf.ShowNoInternetErrorMessage}`;
    } else {
      this.state.showNotification({
        type: "error",
        message: "Host URL not Found",
      });
    }

    this.state._setState((state) => ({
      ...state,
      hostURL: hostURL,
      hostURL1: hostURL1,
      quizSmartURL: quizSmartURL,
      ShowNoInternetErrorMessage: ShowNoInternetErrorMessage,
      searchUrl: searchUrl,
    }));
    if (this.state.token && this.state.token !== "") {
      this.state.monitorReauthentication();
    }
  }

  render() {
    return (
      <SessionContext.Provider value={this.state}>
        {this.props.children}
        <Persist
          name="session-state"
          data={this.state}
          debounce={500}
          onMount={(data) => this.setState(data)}
        />
      </SessionContext.Provider>
    );
  }
}

export default SessionProvider;
